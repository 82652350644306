.main_sidebar {
  width: 17.125rem;
  padding: 2.5rem 1.5rem;
  border-right: 0.5px solid var(--sidebar-border);
  position: fixed;
  left: 0;
  top: 0;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  // overflow-y: auto;

  &.show {
    transition: all 0.3s ease;
  }
}

.sidebar_logo_small {
  img.logo_icon {
    display: none;
  }
}

.sidebar_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-bottom: 60px;
}

.menu_list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;

  ul {
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-size: 14px;
        font-weight: 500;
        color: var(--body-color);
        text-decoration: none;
        background-color: transparent;
        padding: 1rem 1rem;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 10px;
        transition: all 0.3s;

        span.outline_icon {
          position: absolute;
          opacity: 0;
        }

        &:hover {
          background-color: var(--blue-backgound);

          span.outline_icon {
            position: unset;
            opacity: 1;
          }

          span.fill_icon {
            position: absolute;
            opacity: 0;
          }
        }
      }

      a.active_link {
        background-color: var(--blue-backgound);

        span.outline_icon {
          position: unset;
          opacity: 1;
        }

        span.fill_icon {
          position: absolute;
          opacity: 0;
        }
      }
    }
  }
}

.menu_list {
  button.btn_blue {
    width: 100%;
    display: none;
  }
}

.after_login {
  display: none;
  align-items: center;
  justify-content: space-between;
}

.after_cntnt {
  display: flex;
  align-items: center;
  gap: 16px;
}

.avtar_img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.avtar_txt p {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 0;
}

.main_sidebar.show {
  width: 116px;

  span.text_name {
    display: none;
  }

  .logo_txt {
    display: none;
  }

  .logo_icon {
    display: block;
  }
}

.main_sidebar.show {
  .menu_list {
    ul {
      li {
        a {
          justify-content: center;
        }
      }
    }
  }

  .sidebar_logo {
    gap: 0;
  }
}

img.logo_txt {
  width: 150px !important;
}

.logout_btn.logout_btn_sidebar {
  display: none;
}

a.disable_grey {
  color: #6B7694 !important;

  &:hover {
    background-color: transparent !important;
  }

  span.disable_icon {
    display: none;
  }

}

/* responsive */

@media only screen and (max-width: 1200px) {
  .logout_btn.logout_btn_sidebar {
    display: block !important;
  }

  .main_sidebar.show {
    .menu_list {
      ul {
        li {
          a {
            justify-content: unset;
          }
        }
      }
    }
  }

  .after_login {
    display: flex;
    margin-bottom: 35px;
  }

  .main_sidebar {
    transition: all 0.3s ease;
    width: 321px;
    z-index: 9;
    background: linear-gradient(351.21deg, #0c0c0d 33.24%, #262636 95.65%);
    border: none;
    right: -321px;
    left: auto;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    padding: 27px 24px 0;

    &+.main_sidebar_show {
      display: none;
    }

    &.show {
      &+.main_sidebar_show {
        position: fixed;
        width: 100%;
        height: 100dvh;
        background-color: rgb(36 36 45 / 80%);
        left: 0;
        top: 0;
        z-index: 2;
        display: block;
      }
    }
  }

  .main_sidebar.show {
    right: 0;
  }

  .sidebar_logo {
    display: none;
  }

  .menu_list {
    & ul {
      li {
        margin-bottom: 0;

        a {
          padding: 24px 0;
          border-radius: 0;
          border-bottom: 0.5px solid var(--inner-common-border);

          &:hover {
            background-color: transparent;
          }
        }

        a.active_link {
          background-color: transparent;

        }
      }
    }
  }

  .menu_list {
    button.btn_blue {
      display: block;
      padding: 17px 10px;
    }
  }

  .main_sidebar.show {
    width: 321px;

    span.text_name {
      display: block;
    }

    .logo_txt {
      display: block;
    }

    .logo_icon {
      display: none;
    }
  }

  .logout_btn {
    a {
      padding: 16px 0 !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .menu_list {
    & ul {
      & li {
        a {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
}