@import "./header.scss";
@import "./sidebar.scss";
@import "./footer.scss";

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
}

p {
  color: var(--body-color);
  font-size: 1rem;
}

.sidebar_wraper {
  display: flex;
}

.main-content.dashboard_wraper {
  max-width: 1920px;
  margin: 0 auto;
}

.main_wraper {
  width: 100%;
  min-width: 0;
}

.remove_margin {
  margin-left: 106px;
}

ul {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none;
}

img {
  max-width: 100%;
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
  caret-color: #fff;
}

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: unset;
  background-color: transparent;
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  background: #6b7694;
  border-radius: 10px;
  width: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #6b7694;
}

.yellow_btn {
  background-color: var(--yellow-backgound);
  border: 0.5px solid var(--yellow-border);
  font-size: 1.125rem;
  font-weight: 600;
  border-radius: 4px;
  line-height: 17px;
  padding: 6px 6px;
}

.pink_btn {
  background-color: var(--pink-backgound);
  border: 0.5px solid var(--pink-border);
  font-size: 1.125rem;
  font-weight: 600;
  border-radius: 4px;
  line-height: 17px;
  padding: 6px 6px;
}

a {
  text-decoration: none !important;
}

button.btn.btn-secondary {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  padding: 15px 15px;
  border-radius: 8px;
  background-color: var(--secondary-btn);
  color: var(--secondary-btn-color);
  border: 0.5px solid var(--secondary-btn);
}

button.btn.btn-outline-light {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  padding: 15px 15px;
  border-radius: 8px;
}

input:focus,
button:focus,
select:focus {
  outline: none;
  box-shadow: unset !important;
}

select:focus {
  border-color: var(--border-color) !important;
}

.btn_blue {
  font-size: 1rem;
  font-weight: 700;
  padding: 0.9375rem 1rem;
  text-transform: uppercase;
  border-radius: 0.5rem;
}

.btn_blue_large {
  font-size: 15px;
  font-weight: 700;
  padding: 16px 32px 16px 32px;
  text-transform: uppercase;
  border-radius: 0.5rem;
  line-height: 33px;
  height: 65px;
}

li::marker {
  content: none;
}

.top_pill_flx {
  padding: 0.5rem 2rem;
  display: flex;
  gap: 1rem;
}

.top_pill_link {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-flex;
    gap: 1rem;
  }

  a {
    font-size: 1rem;
    font-weight: 500;
    color: var(--body-color);
    display: inline-block;
    padding: 0.1563rem 1.25rem;
    border: 0.0313rem solid var(--border-color);
    border-radius: 1.875rem;
    transition: all 0.3s;

    &:hover {
      background-color: var(--blue-backgound);
      border: 0.0313rem solid var(--blue-border);
    }
  }
}

.slect_categry select {
  font-size: 1rem;
  font-weight: 500;
  color: var(--body-color);
  background-color: transparent;
  padding: 0.375rem 1.25rem;
  border: 0.0313rem solid var(--border-color);
  border-radius: 1.875rem;
  background-image: url("../img/select-arrow-down.svg");
  background-size: unset;
  background-position: 90% 55%;
}

.swiper.hero_slider {
  padding-bottom: 24px;

  .swiper-pagination {
    bottom: -7px;

    span.swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background-color: #35343e;
      opacity: 1;
      margin: 0 4px;
    }

    span.swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #2957ff;
      width: 18px;
      border-radius: 30px;
    }
  }
}

.hero_banner_slider {
  position: relative;
}

.hero_banner_slide {
  background-repeat: no-repeat;
  background-size: cover;
}

.hero_banner_slide {
  border-radius: 16px;
  /* position: absolute;
    left: 0;
    right: 100px;
    top: 0;
    width: 100%;
    */
}

.hero_banner_slider_video {
  position: relative;
  height: 380px;
  border-radius: 16px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 20%);
    border-radius: 16px;
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
}

.multi_genre_flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  padding: 16px 32px;
  width: 100%;
  left: 0;
  top: 0;
}

.multi_genre_txt {
  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--body-color);
    margin-bottom: 0;
  }
}

.multi_genre_btn {
  display: inline-flex;
  gap: 0.5rem;
}

.coachella {
  padding: 0.9375rem 0.9375rem;
  margin: 62px 0 0 120px;
  position: absolute;
  top: 50px;

  h3 {
    font-size: 4.375rem;
    font-weight: 600;
    color: var(--body-color);
    line-height: 3.0625rem;
    margin: 0 0 1rem 1.875rem;
    text-transform: uppercase;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;

    li {
      p {
        font-size: 1.125rem;
        font-weight: 600;
        color: var(--body-color);
        line-height: 18px;
        letter-spacing: 2px;
        margin: 0 0 0 0;
      }

      /*&:nth-of-type(2) {
        margin-left: 10px;
      }

      &:nth-of-type(3) {
        margin-left: 20px;
      }*/
    }
  }
}

.coachella.home_banner_coachella {
  h3 {
    margin-left: 0;
  }
}

.tribe_attending {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  padding: 0 32px 16px 32px;
  width: 100%;
  left: 0;
}

.tribe_attending_list {
  display: flex;
  gap: 10px;
  align-items: center;

  ul {
    display: flex;
    align-items: center;
    li {
      display: inline-block;
      margin-right: -10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.tribe_attending_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.tribe_attending_txt {
  h3 {
    font-size: 1.375rem;
    font-weight: 600;
    color: var(--body-color);
    margin-bottom: -2px;
  }

  p {
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--body-color);
    margin-bottom: 0;
  }
}

.empire_polo {
  .empire_polo_txt {
    color: var(--body-color);
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 18px;
    margin-bottom: 4px;
    padding-right: 15px;
    text-transform: uppercase;
    text-align: right;
    padding: 0 !important;
  }

  .empire_polo_txt.empire_polo_indio {
    padding-right: 0;
    text-align: right;
  }

  .empire_polo_date {
    padding-right: 12px;
  }
}

.buy_area {
  margin: 24px 0;
  padding-left: 2rem;
}

.buy_area_head {
  margin-bottom: 24px;

  h2 {
    font-size: 1.375rem;
    font-weight: 700;
    margin-bottom: 0;
  }
}

.custom_tab {
  margin: 32px 0 0;

  .nav.nav-tabs {
    border: none;
    border-bottom: 0.5px solid #475169;
    padding: 0 24px !important;
  }

  .nav-tabs .nav-link {
    font-size: 1.125rem;
    font-weight: 600;
    color: #abb2c3;
    padding: 15px 32px;
    border: none;
    border-bottom: 2px solid transparent;
  }

  .nav-link.active {
    background-color: transparent;
    color: var(--blue-color);
    border-bottom: 2px solid var(--blue-color);
  }
}

.coachella_music {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  .more_than {
    display: flex;
    align-items: center;
    gap: 32px;
  }
}

.custm_tab_cntnt {
  padding: 32px 32px;
}

.coachella_music_crd {
  padding: 24px 24px;
  background: var(--card-background-two);
  border-radius: 8px;
  margin-bottom: 32px;
}

.coachella_music_festival_txt {
  h3 {
    font-size: 1.49rem;
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: -0.5px;
  }
}

.trending_tag {
  p {
    font-size: 1rem;
    font-weight: 700;
    color: #292833;
    padding: 2px 6px;
    border-radius: 4px;
    background-color: var(--yellow-backgound);
    border-color: var(--yellow-border);
    margin-bottom: 0;
  }
}

.more_than_txt {
  p {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.coachella_music_date_flx {
  display: flex;
  gap: 32px;
}

.coachella_music_date {
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.general_admission_flx {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

.general_admission_crd {
  background: var(--card-background-two);
  padding: 24px 24px;
  border-radius: 8px;
}

.general_admission_crd_head {
  h2 {
    font-size: 1.49rem;
    font-weight: 600;
    margin-bottom: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
  }
}

.weekend_list {
  li {
    margin-bottom: 24px;
  }
}

.weekend_list_txt {
  background-color: var(--card-background-inner);
  padding: 24px 24px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;

  h4 {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.5px;
    margin-bottom: 0;
    padding-right: 12px;
  }

  p {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.weekend_list_btm {
  p {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.more_info_accordian {
  margin: 24px 0 0;

  .accordion-item {
    background: transparent;
    border: 0.5px solid var(--accordian-border);
    border-radius: 8px;
  }

  button.accordion-button {
    background: transparent;
    padding: 13px 16px;
    color: #e6e9ff;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: unset;
  }

  .accordion-button {
    &::after {
      background-image: url(../img/select-arrow-down.svg);
    }
  }
}

.sign_in_modal {
  button.btn-close {
    cursor: pointer;
    display: block;
    border: 1.5px solid #abb2c3;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0;
    font-size: 8px;
    font-weight: 500 !important;
    color: #fff !important;
    opacity: 1 !important;
    transform: translate(-8px, -11px);
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.292786 0.292997C0.480314 0.105526 0.734622 0.000210801 0.999786 0.000210801C1.26495 0.000210801 1.51926 0.105526 1.70679 0.292997L7.99979 6.586L14.2928 0.292997C14.385 0.197487 14.4954 0.121305 14.6174 0.0688959C14.7394 0.0164869 14.8706 -0.0110994 15.0034 -0.0122532C15.1362 -0.013407 15.2678 0.0118947 15.3907 0.0621756C15.5136 0.112456 15.6253 0.18671 15.7192 0.280602C15.8131 0.374495 15.8873 0.486147 15.9376 0.609043C15.9879 0.73194 16.0132 0.863619 16.012 0.996398C16.0109 1.12918 15.9833 1.2604 15.9309 1.3824C15.8785 1.50441 15.8023 1.61475 15.7068 1.707L9.41379 8L15.7068 14.293C15.8889 14.4816 15.9897 14.7342 15.9875 14.9964C15.9852 15.2586 15.88 15.5094 15.6946 15.6948C15.5092 15.8802 15.2584 15.9854 14.9962 15.9877C14.734 15.9899 14.4814 15.8892 14.2928 15.707L7.99979 9.414L1.70679 15.707C1.51818 15.8892 1.26558 15.9899 1.00339 15.9877C0.741189 15.9854 0.490376 15.8802 0.304968 15.6948C0.11956 15.5094 0.0143908 15.2586 0.0121124 14.9964C0.00983399 14.7342 0.110628 14.4816 0.292786 14.293L6.58579 8L0.292786 1.707C0.105315 1.51947 0 1.26516 0 0.999997C0 0.734833 0.105315 0.480525 0.292786 0.292997Z' fill='%23ABB2C3'/%3E%3C/svg%3E%0A");
    position: absolute;
    top: 44px;
    right: 24px;
  }

  .modal-dialog {
    max-width: 616px;
  }

  .modal-content {
    border: none;
    background-color: var(--card-background);
    border-radius: 24px;
  }

  .modal-header {
    border-bottom: none;
    padding: 40px 40px 0 40px;
  }

  .modal-title {
    width: 100%;

    h2 {
      text-align: center;
      font-size: 3.125rem;
      font-weight: 700;
      line-height: 65px;
      margin-bottom: 0;
      letter-spacing: -0.5px;
    }
  }

  .modal-body {
    padding: 0 40px 0 40px;
    text-align: center;

    h4 {
      font-size: 1.875rem;
      font-weight: 700;
      margin-bottom: 32px;
    }

    p {
      font-size: 1.125rem;
      font-weight: 700;
      margin-bottom: 0;

      a {
        color: var(--pink-text-color);
        text-decoration: underline !important;
      }
    }
  }

  .modal-footer {
    padding: 24px 40px 40px 40px;
    justify-content: center;
    border-top: none;

    button.btn.btn-secondary {
      margin: 0;
      width: 100%;
    }
  }

  button.btn-close {
  }

  .phone_number_form {
    padding-top: 32px;
    display: flex;
    gap: 16px;
  }

  .enter_phone_no {
    width: 100%;

    label {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
      color: var(--body-color);
      text-align: left;
    }

    input {
      background: transparent;
      border: 0.5px solid var(--input-border-color);
      border-radius: 8px;
      color: var(--body-color);
      padding: 16.5px 20px 16.5px 68px;
    }
  }

  .phone_label {
    flex: 0 0 100%;

    input.form-control {
      background: transparent;
      border: 0.5px solid var(--input-border-color);
      height: 59px;
      border-radius: 8px;
      font-size: 20px;
      font-weight: 500;
      color: var(--body-color);
      height: 59px !important;
    }

    label {
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 10px;
      color: var(--body-color);
      text-align: left;
      display: block;
    }

    .flag-dropdown {
      background: transparent !important;
      border-right: 1px solid var(--input-border-color) !important;
      border-radius: 8px 0 0 8px;

      .selected-flag:hover {
        background: transparent !important;
      }
    }

    .flag {
      .arrow {
        background-image: url("../img/select-arrow-down.svg");
        width: 16px;
        height: 17px;
        background-repeat: no-repeat;
        border: none !important;
        transform: translate(14px, -7px);
        left: 10px;
      }
    }
  }

  .selected-flag {
    width: 58px !important;
    padding: 0 0 0 12px;
  }

  .react-tel-input {
    .country-list {
      width: 168px;
      background-color: #475169;
      border-radius: 5px;
      top: 115%;
      max-height: 254px;
      text-align: left;
    }

    .country {
      padding: 7px 16px;
    }
  }

  li.country {
    padding: 10px 16px;

    &:hover {
      background-color: transparent !important;
    }

    .flag.af {
      display: none !important;
    }

    span.country-name {
      font-size: 14px;
      line-height: 22px;
      color: var(--body-color);
      font-weight: 400;
      margin-right: 8px;
    }

    span.dial-code {
      font-size: 14px;
      line-height: 22px;
      color: var(--body-color) !important;
      font-weight: 600;
    }
  }

  .country.highlight {
    background-color: transparent !important;
  }

  .selected-flag.open {
    background: transparent !important;
  }

  .country-list {
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #475169;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #6b7694;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.sign_in_modal.categories_modal.create_tribe_modal {
  .modal-body.newModalBodyController {
    padding: 0 80px 80px;
  }
}

.newButtonSpacing.btn.btn-secondary,
.newButtonSpacing.btn.btn-secondary.btn-pad-second,
.btn-pad-second.newButtonSpacing.btn.btn-big-submit {
  padding: 15px !important;
}

.sign_in_modal.categories_modal.create_tribe_modal.newUserDetailsModal {
  .name_your_tribe {
    .form-control {
      padding: 15px 20px;
    }
  }
}

.welcome_modal {
  button.btn-close {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
  }

  .welcome_logo {
    /* padding: 64px 99px;*/
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    max-width: 342px;
    margin: 0 auto;
    transform: translateY(-50%);
  }

  .flex_login_container {
    position: relative;
    /* display: grid;
        grid-template-columns: repeat(2, 1fr); */
  }

  .login_video {
    width: 100%;
    height: 765px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(36 36 45 / 50%);
      border-radius: 24px;
    }

    video {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 24px;
    }

    .login_video_img {
      height: 100%;

      img {
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
      }
    }

    video.intergration_videos {
      object-position: right;
    }
  }

  .modal-dialog {
    max-width: 1080px;
  }

  .welcome_logo_img {
    margin-bottom: 40px;
  }

  .welcome_logo_txt {
    margin-bottom: 80px;

    p {
      font-size: 1.375rem;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  .welcm_btn_list {
    li {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      button {
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #595860;
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: center;
        padding: 13.5px 12px;
        border-radius: 8px;
      }

      .white_btn {
        background-color: #fff;
        border: 1px solid #fff;
      }

      .facebook_btn {
        background-color: #3a5998;
        border: 1px solid #3a5998;
        color: #fff;
      }

      .sign_up_with {
        background-color: var(--blue-backgound);
        color: #fff;
        border: 1px solid var(--blue-border);
      }
    }
  }

  .already_have {
    margin-top: 24px;

    p {
      font-size: 1.125rem;
      font-weight: 600;
      margin-bottom: 24px;

      a {
        color: var(--pink-text-color);
        text-decoration: underline !important;
      }
    }

    p.signin_up {
      font-weight: 500;
      margin-bottom: 0;
    }
  }
}

.otp_code {
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  label {
    text-align: center !important;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 4px 4px;
  }

  input {
    background: transparent;
    border: 0.5px solid var(--input-border-color);
    font-size: 40px;
    font-weight: 500;
    border-radius: 8px;
    margin: 0;
    height: 72px;
    width: 72px !important;
    color: var(--body-color);
  }
}

.welcome_modal.completeyour_profile {
  .modal-header {
    padding: 0;

    .modal-title {
      margin-bottom: 0;
    }

    button.btn-close {
      position: absolute;
      top: 46px;
      right: 23px;
      z-index: 9;
    }
  }

  .welcome_logo {
    padding: 40px 56px;
    text-align: unset;
  }

  .completeyour_profile h2 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 32px;
  }

  .personal_info {
    h2 {
      font-size: 26px;
      font-weight: 600;
      letter-spacing: -0.5px;
      line-height: 28px;
      margin-bottom: 32px;

      .cam_flash {
        transform: translateY(-3px);
        margin-right: 5px;
      }
    }

    span {
      background-color: var(--pink-backgound);
      display: inline-block;
      width: 44px;
      height: 44px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      font-weight: 600;
      border-radius: 8px;
      margin-right: 14px;
    }

    .progress {
      height: 6px;
      background-color: var(--secondary-btn);

      .progress-bar {
        background-color: var(--pink-backgound);
        border-radius: 30px;
      }
    }
  }

  .form_row {
    position: relative;
    margin-bottom: 16px;

    span.update_edit_number {
      position: absolute;
      top: 50%;
      right: 14px;
      transform: translateY(-50%);
    }

    &:last-child {
      margin-bottom: 0;
    }

    input {
      background: transparent;
      width: 100%;
      border: 0.5px solid var(--complete-profile-border);
      border-radius: 8px;
      padding: 9.5px 16px 9.5px 42px;
      font-size: 18px;
      font-weight: 500;
      color: var(--body-color);

      &:-webkit-autofill {
        background-color: transparent;
      }

      &::placeholder {
        color: #e6e9ff;
      }
    }

    .form_row_icon {
      position: absolute;
      top: 12px;
      left: 16px;
      z-index: 1;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker__month-container,
    .react-datepicker__header {
      background-color: var(--card-background-inner);
    }

    .react-datepicker {
      border: 0.5px solid var(--complete-profile-border);
    }

    .react-datepicker__day-name,
    .react-datepicker__current-month {
      color: #fff;
      display: none;
    }

    .react-datepicker__day {
      color: #fff;

      &:hover {
        background-color: var(--pink-backgound);
      }
    }

    .react-datepicker__day.react-datepicker__day--disabled {
      color: rgb(255 255 255 / 52%);
    }

    .react-datepicker__today-button {
      display: none;
    }

    .react-datepicker__day.react-datepicker__day--selected {
      background-color: var(--pink-backgound);
    }

    .react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--today {
      background-color: var(--pink-backgound);
    }

    .react-datepicker__header {
      border-bottom: 0.5px solid var(--complete-profile-border);
    }

    .react-datepicker {
      background-color: var(--card-background-inner);
      border-radius: 16px;
    }

    .react-datepicker__month-container {
      border-radius: 16px;
    }

    .react-datepicker__header {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    .react-datepicker-popper[data-placement^="top"]
      .react-datepicker__triangle {
      fill: #25252d;
      color: #25252d;
    }
  }

  .completeyour_profile_btn {
    margin-top: 32px;

    button {
      width: 100%;
    }
  }

  .css-13cymwt-control {
    background-color: transparent;
    border: 0.5px solid var(--complete-profile-border);
    border-radius: 8px;
    background-image: url(../img/Selectarrowdown.svg);
    background-repeat: no-repeat;
    background-position: 97% 57%;
    box-shadow: unset;

    svg {
      display: none;
    }
  }

  .css-qbdosj-Input {
    input {
      &::placeholder {
        color: #fff !important;
        font-size: 20px !important;
      }
    }
  }

  .css-d7l1ni-option,
  .css-tr4s17-option {
    background-color: transparent;
  }

  .react-select__control {
    background: transparent;
    box-shadow: unset;
    background-image: url(../img/Selectarrowdown.svg);
    background-repeat: no-repeat;
    background-position: 97% 57%;
    border-radius: 8px;
    border: 0.5px solid var(--complete-profile-border);

    svg {
      display: none;
    }

    &:hover {
      border-color: none;
    }
  }

  .react-select__menu {
    background-color: #475169;
    padding: 5px 16px;
    border-radius: 5px;
    z-index: 9;
  }

  .react-select__menu-list {
    padding: 0;

    div {
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      padding: 10.5px 0;
      border-bottom: 0.5px solid #6b7694;

      &:hover {
        background-color: transparent;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .react-select__option--is-selected {
    color: #ff53de !important;
  }

  .css-1dimb5e-singleValue {
    color: #e6e9ff;
    font-size: 18px;
    font-weight: 500;
  }

  span.css-1u9des2-indicatorSeparator {
    display: none;
  }

  .react-select__control .react-select__value-container {
    padding: 8px 16px 8px 42px;
  }

  .flex_login_container {
    display: flex;
  }

  .login_video {
    flex: 0 0 480px;
    max-width: 480px;
  }

  .react-select__placeholder {
    color: #e6e9ff;
    font-size: 18px;
    font-weight: 500;
  }

  .react-datepicker__input-container {
    input {
      color: #fff;
    }
  }

  .info_date_picker {
    position: relative;

    span {
      display: inline-block;
      // position: absolute;
      // width: 11px;
      // height: 11px;
      // right: 6px;
      color: #fff;
      // top: 11px;
      cursor: pointer;
    }
  }

  .placeholder {
    position: absolute;
    top: 10px;
    pointer-events: none;
    color: #e6e9ff;
    opacity: 1;
    background: transparent;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    left: 42px;

    span {
      color: #595860;
    }
  }

  input.form-control:focus ~ .placeholder {
    display: none;
  }
}

.tool_tip {
  --bs-tooltip-opacity: 1;

  .tooltip-arrow:before {
    content: none;
  }

  .tooltip-inner {
    font-family: poppins;
    background-color: #475169;
    padding: 18px 16px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    left: -91px;
    top: -6px;
    position: relative;
    border-radius: 5px;
  }
}

h3.profile_label {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}

.profile_photo_upload {
  label {
    width: 100%;
    text-align: center;
    background-color: #292833;
    border: 1px dashed var(--complete-profile-border);
    padding: 32px 32px;
    border-radius: 16px;
    cursor: pointer;
  }

  .profile_photo_txt {
    margin-top: 24px;

    h4 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 16px;

      span {
        color: var(--blue-color);
        text-decoration: underline;
      }
    }

    p {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
}

.completeyour_profile_btn.profile_photo_btn {
  display: flex;
  gap: 15px;
}

.show_profile_pic {
  border: 1px solid #6b7694;
  border-radius: 16px;
  padding: 16px 16px;
  padding-right: 42px;
  position: relative;

  .show_profile_pic_flx {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .show_profile_img {
    max-width: 80px;
    width: 100%;
    height: 80px;
    border-radius: 16px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }
  }

  .show_profile_txt p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    word-break: break-all;
  }

  .close_btn {
    position: absolute;
    right: 16px;
    top: calc(50% - 12px);

    button {
      background-color: transparent;
      border: none;
      padding: 0;
    }
  }
}

.select_bage_flx {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .badge.bg-primary {
    font-size: 16px;
    font-weight: 500;
    background-color: transparent !important;
    border: 0.5px solid var(--border-color);
    border-radius: 30px;
    padding: 6.5px 20px;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      background-color: var(--blue-backgound) !important;
      border: 0.5px solid var(--blue-border);
    }
  }

  .badge.bg-primary.is_active {
    background-color: var(--blue-backgound) !important;
    border: 0.5px solid var(--blue-border);
  }
}

.social_media_crd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social_media_icon_flx {
  display: flex;
  align-items: center;
  gap: 12px;

  .social_media_txt {
    p {
      font-size: 14px;
      font-weight: 500;
      color: #abb2c3;
      margin-bottom: 0;
    }
  }
}

ul.social_media_list {
}

ul.social_media_list li {
  padding: 24px 0;
  border-bottom: 0.5px solid var(--accordian-border);
  border-left: none;
  border-right: none;
}

.connect_txt {
  span {
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: -0.5px;
    text-decoration: underline;
    color: var(--pink-text-color);
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

.social_media_group {
  min-height: 490px;
  max-height: 490px;
  overflow-y: scroll;
  padding-right: 10px;

  /* width */
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #6b7694;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #6b7694;
  }
}

.cap_cha {
  min-height: 490px;
}

.inner_wraper {
  padding: 24px 32px 32px;
}

.back_profile {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
  margin-top: 16px;
}

.back_profile_txt {
  p {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.profile_wraper {
  display: flex;
  align-items: center;
  gap: 72px;
  margin-bottom: 24px;

  & > div {
    flex: 1 0 0;
  }
}

.profile_wraper_img {
  width: 100%;
  height: 512px;
  border-radius: 16px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 16px;
  }
}

.user_profile_cntnt {
  padding-bottom: 40px;
  border-bottom: 0.5px solid var(--inner-common-border);
}

.user_profile_cntnt_txt {
  margin-bottom: 16px;

  h2 {
    font-size: 2.1875rem;
    font-weight: 700;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      width: 46px;
      height: 46px;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background-color: var(--white-background);
    }
  }

  p {
    font-size: 1.625rem;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.5px;
    margin-bottom: 0;
  }
}

.verify_badge {
  span.badge.bg-success {
    background-color: var(--pink-backgound) !important;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 7.5px 8px;

    img {
      transform: translateY(-1px);
    }
  }
}

.verify_badge_with_name {
  display: none;
}

.edit_icon_icon_dsk {
  display: none;
}

.user_profile_social {
  padding: 40px 0 0;
}

.user_profile_social_head {
  margin-bottom: 32px;

  h3 {
    font-size: 1.625rem;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.5px;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.user_profile_social_media {
  ul {
    display: flex;
    gap: 24px;
  }
}

.profile_information {
  margin-bottom: 24px;
}

.profile_information_crd {
  padding: 24px 24px;
  background-color: var(--card-background-two);
  border-radius: 18px;
}

.profile_information_crd_head {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.5px;
    margin-bottom: 0;

    span {
      cursor: pointer;
    }
  }
}

.profile_information_flex {
  display: flex;
  flex-wrap: wrap;
  gap: 72px;

  .col_6 {
    flex: 1 0 0;
  }
}

ul.profile_information_list {
  li {
    padding: 18px 0;
    border-bottom: 0.5px solid var(--inner-common-border);
  }
}

.profile_information_list_txt {
  h4 {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--light-lable-color);
    margin-bottom: 6px;
  }

  p {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.profile_information_list_txt_email {
  h4 {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--light-lable-color);
    margin-bottom: 6px;
  }

  p {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.profile_information_list_txt p::first-letter {
  text-transform: uppercase;
}

.integrations_crd_flx {
  display: flex;
  gap: 16px;

  .integrations_crd_txt {
    p {
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
}

.integrations_info {
  ul.profile_information_list {
    li {
      padding: 22.5px 0;
    }
  }
}

.preferences_infomation {
  .profile_information_crd_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  button {
    background-color: transparent;
    border: none;
  }
}

.music_genre {
  margin-bottom: 24px;

  .music_genre_head {
    margin-bottom: 16px;

    h3 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  .select_bage_flx {
    .primary_fill_badge.badge.bg-primary {
      background-color: var(--blue-backgound) !important;
      border-color: var(--blue-border);
    }
  }
}

.sign_in_modal.categories_modal {
  .modal-header {
    padding: 32px 40px 24px 32px;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    .modal-title.h4 {
      font-size: 30px;
      font-weight: 700;
      color: var(--body-color);
      margin-bottom: 0;
    }
  }

  .modal-body {
    padding: 32px 32px;
  }

  .btn-close {
    cursor: pointer;
    display: block;
    border: 1.5px solid #abb2c3;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0;
    font-size: 8px;
    font-weight: 500 !important;
    color: #fff !important;
    opacity: 1 !important;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.292786 0.292997C0.480314 0.105526 0.734622 0.000210801 0.999786 0.000210801C1.26495 0.000210801 1.51926 0.105526 1.70679 0.292997L7.99979 6.586L14.2928 0.292997C14.385 0.197487 14.4954 0.121305 14.6174 0.0688959C14.7394 0.0164869 14.8706 -0.0110994 15.0034 -0.0122532C15.1362 -0.013407 15.2678 0.0118947 15.3907 0.0621756C15.5136 0.112456 15.6253 0.18671 15.7192 0.280602C15.8131 0.374495 15.8873 0.486147 15.9376 0.609043C15.9879 0.73194 16.0132 0.863619 16.012 0.996398C16.0109 1.12918 15.9833 1.2604 15.9309 1.3824C15.8785 1.50441 15.8023 1.61475 15.7068 1.707L9.41379 8L15.7068 14.293C15.8889 14.4816 15.9897 14.7342 15.9875 14.9964C15.9852 15.2586 15.88 15.5094 15.6946 15.6948C15.5092 15.8802 15.2584 15.9854 14.9962 15.9877C14.734 15.9899 14.4814 15.8892 14.2928 15.707L7.99979 9.414L1.70679 15.707C1.51818 15.8892 1.26558 15.9899 1.00339 15.9877C0.741189 15.9854 0.490376 15.8802 0.304968 15.6948C0.11956 15.5094 0.0143908 15.2586 0.0121124 14.9964C0.00983399 14.7342 0.110628 14.4816 0.292786 14.293L6.58579 8L0.292786 1.707C0.105315 1.51947 0 1.26516 0 0.999997C0 0.734833 0.105315 0.480525 0.292786 0.292997Z' fill='%23ABB2C3'/%3E%3C/svg%3E%0A");
  }

  .modal-dialog {
    max-width: 803px;
  }

  .completeyour_profile_btn.profile_photo_btn {
    width: 100%;

    button {
      width: 100%;
    }
  }

  .modal-footer {
    padding: 32px 32px;
    border-top: 0.5px solid var(--inner-common-border);
  }
}

.music_genre.categoties_genre {
  margin-bottom: 0;

  .music_genre_head {
    margin-bottom: 32px;

    h3 {
      font-size: 1.375rem;
    }
  }
}

.more_info_accordian.fillter_accordian {
  .accordion-item {
    border: none;
    border-bottom: 0.5px solid var(--inner-common-border);
    border-radius: 0;

    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .accordion {
    .accordion-item {
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .choose_date {
    display: flex;
    gap: 24px;
    margin: 32px 0 0;

    .form_row {
      flex: 1;
      position: relative;

      label {
        text-align: left;
        display: block;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 10px;
      }

      input {
        background-color: transparent;
        border: 0.5px solid var(--input-border-color);
        border-radius: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        padding: 10px 16px;
        margin-bottom: 32px;
        color: var(--placeholder-color);

        &::placeholder {
          color: var(--placeholder-color);
        }
      }
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker__input-container {
      input {
        width: 100%;
        margin-bottom: 0;
      }
    }

    .react-datepicker-popper {
      left: 0;
      right: 0;
      transform: translate(0px, 91px) !important;
      width: 100%;
      z-index: 12;
    }

    .react-datepicker {
      width: 100%;
      border: none;
      background-color: transparent;
    }

    .react-datepicker__month-container {
      width: 100%;
      background-color: #475169;
      border-radius: 8px;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      font-family: poppins;
      font-size: 15px;
      font-weight: 400;
      line-height: 32px;
      width: 32px;
      height: 32px;
      margin-left: 8px;
      margin-right: 8px;
      color: #fff;
      border-radius: 50%;
    }

    .react-datepicker__day-name {
      color: #8a94ad;
    }

    .react-datepicker__today-button {
      display: none;
    }

    .react-datepicker__header {
      background-color: #475169;
      border: none;
      padding-top: 24px;
    }

    .react-datepicker__current-month {
      font-family: poppins;
      font-size: 15px;
      font-weight: 400;
      line-height: 23px;
      color: #fff;
      padding-left: 20px;
      text-align: left;
      padding-bottom: 10px;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: #2957ff;
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      background-color: #2957ff;
    }

    svg.react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__day-names {
      padding: 0 6px;
    }
  }

  .accordion-body {
    padding: 0 0 32px 0;
  }

  button.accordion-button {
    padding: 32px 0;
    font-size: 22px;
    font-weight: 600;
    color: var(--body-color);
  }
}

h2.back_arrow_signin {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .back_profile_icon {
    display: none;
  }
}

.notification_bar {
  display: flex;
  align-items: center;
  gap: 16px;

  .admin_img_header {
    width: 40px;
    height: 40px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.is_login {
  display: flex;
  flex-direction: row;

  .btn_blue {
  }

  .header_logo {
    display: none;
  }
}

.mobile_header_top_m {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 5px;
}

.header_serch {
  display: none;
}

.welcome_modal.completeyour_profile {
  .welcome_logo {
    position: unset !important;
    max-width: unset;
    transform: unset;
    margin: 0;
    width: 100%;
  }

  .login_video {
    height: auto;

    &::before {
      content: none;
    }

    video {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h2.back_arrow_signin {
    justify-content: unset;
  }
}

.completeyour_profile_photo {
  .completeyour_profile {
    display: none;
  }
}

.connect_icon {
  svg {
    display: none;
  }
}

.main_tittle {
  display: none;
}

.festival_playlist_crd {
  margin-bottom: 24px;
  border-radius: 16px;
}

.festival_playlist {
  display: flex;
  align-items: center;
  gap: 32px;
}

.festival_playlist_txt {
  h2 {
    font-size: 26px;
    font-weight: 600;
    letter-spacing: -0.5px;
    margin-bottom: 16px;
  }
}

.festival_playlist {
  .festival_playlist_txt {
    h2 {
      margin-bottom: 0;
    }
  }
}

.festival_playlist_btn {
  display: flex;
  gap: 16px;

  a {
    display: inline-block;
    border: 0.5px solid #fff;
    padding: 16px 48px;
    border-radius: 70px;
  }
}

.event_information_desc {
  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 0;
  }
}

.more_info_accordian.fillter_accordian.lineup_accordian {
  .accordion-item {
    border-bottom: 0.5px solid var(--accordian-border);
    border-right: none;
    border-left: none;
    border-top: none;

    &:last-child {
      border-bottom: none;
    }
  }

  .accordion-body {
    padding: 0 0 24px;
  }

  button.accordion-button {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    padding: 24px 16px 24px 0;

    border-radius: 0;

    span {
      margin-right: 8px;
    }
  }
}

.lana_dal_rey_head {
  margin-bottom: 8px;

  h3 {
    font-size: 35px;
    font-weight: 700;
    line-height: 52px;
  }
}

.lana_dal_rey_list_flx {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

ul.lana_dal_rey_list {
  li {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: var(--body-color);
  }
}

.calender_slide_img img {
  img {
    width: 100%;
  }
}

.swiper.schedule_slider {
  padding-bottom: 38px;

  & .swiper-pagination {
    span.swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background-color: #35343e;
      opacity: 1;
      margin: 0 4px;
    }

    span.swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #2957ff;
      width: 18px;
      border-radius: 30px;
    }
  }

  .calender_slide_img {
    position: relative;

    img {
      width: 100%;
    }

    .scaling_icon {
      position: absolute;
      right: 8px;
      bottom: 8px;
      width: 40px;
      height: 40px;
      display: none;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.upcoming_tab_flx {
  display: flex;
  justify-content: space-between;

  .form_warper {
    max-width: 361px;

    input.form-control {
      padding: 9.5px 16px 9.5px 48px;
    }
  }
}

.upcomming_event_gird {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
}

.upcoming_tab_flx {
  margin-bottom: 24px;
}

.upcoming_tab_head {
  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    margin-bottom: 0;
  }

  .callHandIcon {
    width: 25px;
    height: 25px;
  }
}

.sign_in_modal.categories_modal.tribein_modal {
  .modal-title.h4 {
    display: none;
  }

  .modal-header {
    padding: 31px 24px 24px 32px;
  }

  .modal-body {
    h4 {
      font-size: 55px;
      font-weight: 700;
      line-height: 70px;
      margin: 10px 0 16px;
    }
  }

  .tribesmember_slide_txt {
    max-width: 665px;
    margin: 0 auto;

    p {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 32px;

      span {
        color: var(--pink-text-color);
      }
    }
  }

  .edit_your_age {
    margin: 10px 0 24px;

    a {
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: var(--body-color);
      text-decoration: underline !important;
    }
  }

  .skip_btn {
    margin-top: 15px;

    a {
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: var(--body-color);
      text-decoration: underline !important;
    }
  }

  .modal-footer {
    padding: 32px 32px 9px;
  }

  .tribesmember_slide_img {
    text-align: center;
  }

  .swiper.schedule_slider {
    padding-bottom: 57px;
  }
}

.reportsuccessfully_txt {
  text-align: center;

  h2 {
    // font-size: 35px;
    font-weight: 500;
    line-height: 40px;
    margin-bottom: 25px;
  }

  p {
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 25px;
    margin-bottom: 32px !important;
  }
}

.logout_btn {
  a {
    font-size: 16px;
    font-weight: 400;
    color: #ff4848;
    padding: 16px 16px;
    border-radius: 8px;
    display: block;
    text-align: left;

    .logout_icon {
      margin-right: 16px;
    }
  }
}

.sign_in_modal.categories_modal.request_successfully_modal {
  .reportsuccessfully_icon {
    margin-bottom: 35px;
  }

  .modal-dialog {
    max-width: 620px;
  }
}

.inner_wraper.chat_inner_wrapper {
  padding: 0 32px 16px;
  border-bottom: 0.5px solid #6b7694;
}

.chat_module_flx {
  position: relative;
  display: flex;
  width: 100%;
  flex: 1;
  min-height: 0;
}

.inner_join_tribe_heading {
  padding: 4px 24px 16px;
  border-bottom: 0.5px solid var(--inner-common-border);

  .back_profile {
    margin-bottom: 0;
  }
}

.tribes_you_form {
  margin-bottom: 16px;

  .filter_btn {
    .dropdown-toggle.show.btn.btn-success {
      background-color: transparent;
    }

    .dropdown-menu {
      min-width: 171px;
      background-color: #475169;
      padding: 15px 16px 15px 16px;
      border-radius: 5px;
      transform: translate(-146px, 26px) !important;

      h3 {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 8px;
      }

      ul.check_lis {
        li {
          margin-bottom: 10px;
        }

        label.form-check-label {
          font-size: 12px;
          line-height: 18px;
          color: var(--body-color);
          margin-left: 8px;
          transform: translateY(2px);
        }

        .form-check-inline {
          margin-right: 0;
        }

        .form-check-input:checked[type="checkbox"] {
          background-color: var(--pink-backgound);
        }
      }
    }
  }

  input.form-control {
    padding: 9.5px 16px 9.5px 48px;
  }

  .filter_btn {
    button {
      background-color: transparent;
      border: none;
      padding: 0;

      &::after {
        content: none;
      }
    }

    .dropdown {
      button {
        &:active {
          background-color: transparent;
        }
      }
    }
  }
}

.chat_wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.tribes_you_part {
  display: flex;
  flex-direction: column;
  flex: 0 0 400px;
  max-width: 400px;
  border-right: 0.5px solid var(--inner-common-border);
  height: 100%;

  .tribes_you_part_bx {
    padding: 24px 24px 0;
  }

  .tribeyoucard_bx {
    padding: 0 24px 24px;
    flex: 1;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: unset;
      background-color: transparent;
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: #6b7694;
      border-radius: 10px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #6b7694;
    }
  }
}

.tribes_you_part_head {
  margin-bottom: 16px;

  h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    margin-bottom: 0;
  }
}

.tribe_you_crd {
  display: flex;
  gap: 16px;
  border: 0.5px solid var(--card-background);
  background-color: var(--card-background);
  border-radius: 8px;

  overflow: hidden;
  margin-bottom: 16px;
}

.tribe_you_cntnt_lft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tribe_you_crd.is_slected {
  border: 0.5px solid var(--card-border-tribe);
  background-color: #35343e;
}

.tribe_you_img {
  max-width: 117px;
  width: 100%;
  height: 134px;
}

.tribe_you_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tribe_you_cntnt {
  display: flex;
  flex: 1 0 0;
  padding: 16px 16px 10px 0;
  justify-content: space-between;
}

.tribe_you_cntnt_txt {
  h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 32px;
  }
}

.tribe_you_crd {
  .tribe_attending_img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
  }

  .tribe_attending_list {
    gap: 13px;

    ul {
      li {
        margin-right: -5px;
      }
    }

    .tribe_attending_txt {
      p {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 0;
      }
    }
  }

  .tribe_you_time {
    text-align: right;

    p {
      font-size: 10px;
      font-weight: 500;
      margin-bottom: 8px;
      color: var(--secondary-btn-color);
      line-height: 15px;
    }

    span {
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      color: var(--body-color);
      background-color: var(--pink-backgound);
      width: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      border-radius: 50%;
    }
  }

  .tribe_you_cntnt_rgt {
    flex: 0 0 47px;
    margin-left: 8px;
  }
}

/* create_tribe_modal */
.sign_in_modal.categories_modal.create_tribe_modal {
  .modal-header {
    padding: 48px 24px 32px 24px;

    .modal-title.h4 {
      font-size: 35px;
      line-height: 52px;
    }

    .btn-close {
      position: absolute;
      top: 42px;
      right: 24px;
    }
  }

  .modal-body {
    padding: 32px 32px 24px;
  }

  .name_your_tribe {
    h3 {
      text-align: left;
      font-size: 26px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: -0.5px;
      margin-bottom: 24px;
    }

    p {
      text-align: left;
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      margin-bottom: 24px;
    }

    .form-control {
      background-color: transparent;
      border: 0.5px solid var(--input-border-color);
      border-radius: 8px;
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      padding: 7.5px 16px;
      margin-bottom: 32px;
      color: var(--placeholder-color);

      &::placeholder {
        color: var(--placeholder-color);
      }
    }

    .sreach_by_name {
      position: relative;

      .form-control {
        background-image: url("../img/search-icon.svg");
        background-repeat: no-repeat;
        background-position: 2%;
        padding-left: 44px;
      }
    }
  }

  .sugges_tion_head {
    h3 {
      font-size: 22px;
      letter-spacing: -0.5px;
    }
  }

  .sugges_tion_flx {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .sugges_tion_crd {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: transparent;
    border: 1px solid var(--inner-common-border);
    border-radius: 70px;
    padding: 4px 12px 4px 4px;
  }

  .sugges_tion_list {
    position: relative;
    width: 100%;

    .sugges_tion_logo_img {
      position: absolute;
      right: -1px;
      bottom: -6px;
    }
  }

  .sugges_tion_logo_img {
    position: absolute;
  }

  .sugges_tion_img {
    width: 32px;
    height: 32px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .sugges_tion_name {
    h3 {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      margin-bottom: 0;
    }
  }

  .or_look {
    margin-top: 32px;

    a {
      font-size: 18px;
      font-weight: 500;
      text-decoration: underline !important;
      display: block;
      text-align: left;
      color: var(--body-color);
    }
  }

  .copy_a_link {
    margin-top: 32px;
  }

  .copy_a_link_btn {
    button {
      width: 100%;
    }
  }

  .search_tribe {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    text-align: left;
    margin-bottom: 32px;

    button {
      background-color: var(--blue-backgound);
      border: none;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: var(--body-color);
      line-height: 24px;
      padding: 6px 32px 6px 12px;
      position: relative;
      border-radius: 70px;

      img {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .please_ensure {
    margin-top: 20px;
    background-color: var(--card-background-tribe);
    border-radius: 8px;
    padding: 24px 24px;

    p {
      font-size: 22px;
      font-weight: 600;
      line-height: 33px;
      margin-bottom: 0;
    }
  }
}

.chat_flx {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow-x: hidden;

  .header-wrap {
    border-bottom: 0.5px solid var(--inner-common-border);
    padding-bottom: 12px;
    @media (min-width: 768px) {
      padding-bottom: 0px;
    }
  }

  .chat_header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .chat_header_flx {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;

    .back_arrow_chat {
      display: none;
    }
  }

  .chat_menu_flx {
    display: flex;
    gap: 16px;
    align-items: center;
    span {
      cursor: pointer;
      display: inline-block;
    }
  }

  .chat_header_img {
    max-width: 56px;
    width: 100%;
    height: 56px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .chat_header {
    padding: 16px 24px;
    width: 100%;
  }

  .chat_header_cntnt {
    h2 {
      font-size: 22px;
      font-weight: 600;
      line-height: 33px;
      margin-bottom: 0;
    }

    ul {
      display: flex;

      li {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #e6e9ff;
        position: relative;
        padding: 0 15px;

        &::before {
          content: "";
          position: absolute;
          left: -3px;
          background-color: #fff;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
        }

        &:first-child {
          padding-left: 0;

          &::before {
            content: none;
          }
        }
      }
    }
  }
}

// Suggestion Modal for mobile

.sign_in_modal.categories_modal.fillter_categories_modal.suggestion_mobile_sm {
  display: none;
}

.sugges_tion_flx.new_sugges_tion_flx_controller {
  flex-direction: column;

  .sugges_tion_crd {
    border: unset !important;

    .sugges_tion_list {
      width: auto;
    }
  }
}

.user_chat {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 32px 32px;
  flex: 1;
  overflow-y: auto;
  // margin-right: 4px;

  .sender_chat_user {
    display: flex;
    justify-content: flex-end;
    gap: 13px;
    margin-bottom: 24px;
  }

  .chat_menu_flx {
    display: flex;
    gap: 24px;
  }

  .sender_chat {
    background-color: var(--blue-backgound);
    padding: 8px 16px 16px;
    border-radius: 8px;
    border-top-right-radius: 0;

    .chat_img {
      width: 32px;
      height: 32px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h4 {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      color: var(--yellow-color);
      margin-bottom: 8px;
    }

    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      margin-bottom: 0;
      word-break: break-word;
    }
  }

  .recever_chat_user {
    justify-content: start;
    flex-direction: row-reverse;

    .sender_chat {
      background-color: var(--card-background-inner);
      border-radius: 8px;
      border-top-left-radius: 0;

      h4 {
        color: var(--pink-text-color);
      }
    }
  }

  .recever_chat_user-poll {
    justify-content: start;
    flex-direction: row-reverse;

    // .sender_chat {
    //   background-color: var(--card-background-inner);
    //   border-radius: 8px;
    //   border-top-left-radius: 0;

    //   h4 {
    //     color: var(--pink-text-color);
    //   }
    // }
  }

  .media_sender {
    display: flex;
    justify-content: end;
    margin: 0 0 0px 0;

    .media_sender_img {
      width: 321px;
      height: 202px;
      border-radius: 10px;

      img {
        width: 100% !important;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }

  .media_recever {
    justify-content: start;
    margin: 0 0 0px 0px;
  }

  .user_map {
    max-width: 362px;
    width: 100% !important;
    height: 263px !important;
    border-radius: 8px;
    border: 8px solid var(--blue-border);
    margin: 0 0 0 auto;
    border-top-right-radius: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .user_map_recever {
    border: 8px solid var(--pink-border);
    border-radius: 8px;
    border-top-left-radius: 0;
    margin: auto 0 0 0;
  }
}

.message_first {
  padding: 16px 0 0;
}

.user_chat_first {
  height: 100%;
  display: flex;
  flex-direction: column;

  .first_message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    h4 {
      font-size: 22px;
      font-weight: 600;
      line-height: 33px;
      color: #e6e9ff;
      margin-bottom: 0;
    }
  }

  .break_the {
    text-align: right;
    padding: 0 16px;

    span {
      cursor: pointer;
      display: inline-block;
      border-radius: 30px;
      background-color: var(--pink-backgound);
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      padding: 8px 18px;
      margin-bottom: 0;
      color: var(--body-color);
      margin-bottom: 16px;
    }
  }

  .ice_breaker_idea {
    background-color: var(--card-background);
    padding: 16px 16px 0;
  }

  .ice_breaker_idea_head {
    margin-bottom: 16px;

    h3 {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      margin-bottom: 0;
    }
  }

  .ice_breaker_idea_flex {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    span {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: var(--body-color);
      padding: 7px 16px;
      border-radius: 30px;
      border: 0.5px solid var(--plus-border);
    }
  }
}

.custom_tab_chat {
  max-width: 1440px;
  width: 100%;
  margin-top: 0;
  margin: 0 auto;
  border: none;
  border-top: 0.5px solid #475169;
  border-bottom: 0.5px solid #475169;

  ul.nav.nav-tabs {
    padding: 0 !important;
    border: none;
    background-color: #24242d;
    border-bottom: 0.5px solid #475169;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    &::-webkit-scrollbar {
      height: 4px;
    }
  }

  .nav-tabs .nav-link {
    border: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #abb2c3;
    padding: 11px 24px;
  }

  .nav-link.active {
    background-color: transparent;
    color: var(--blue-color);
    border-bottom: 2px solid var(--blue-color);
  }

  .gif_grid_bx {
    max-height: 308px;
    overflow-y: auto;

    .gif_grid {
      padding: 24px 16px 16px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 16px;

      .gif_img {
        height: 91px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.media_footer {
  background-color: var(--primary-800);
  padding: 24px 16px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;

  .media_footer_flx {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .plus_icon {
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      width: 18px;
    }
    span {
      background-color: transparent;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      border: 0.5px solid var(--plus-border);
    }

    .dropdown {
      button {
        background-color: transparent;
        padding: 0;
        border: none;

        &:after {
          content: none;
        }

        span {
          svg {
            transform: unset;
            transition: all 0.3s;
          }
        }
      }

      .dropdown-menu {
        min-width: 308px;
        background-color: var(--card-background);
        padding: 12px 24px;
        border-radius: 16px;

        a.dropdown-item {
          font-size: 18px;
          font-weight: 500;
          line-height: 27px;
          color: #e6e9ff;
          padding: 12px 0;
          display: flex;
          align-items: center;
          gap: 8px;
          img {
            margin-right: 8px;
          }

          &:hover,
          &:focus {
            background-color: transparent;
          }
        }
      }
    }

    .share_my {
      h3 {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        margin: 12px 0 12px;
      }
    }

    .show.dropdown {
      button {
        span {
          svg {
            transform: rotate(45deg);
            transition: all 0.3s;
          }
        }
      }
    }
  }

  .media_input {
    width: 100%;
    position: relative;

    .form-control {
      &::placeholder {
        color: #8a94ad;
      }
    }

    .emoji_group {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: center;

      span {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        color: #dee2e6;
      }
    }
  }

  .media_send_btn {
    button {
      background-color: var(--pink-backgound);
      width: 40px;
      height: 40px;
      border-radius: 10px;
      padding: 0;
      border: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
    }
    svg {
      width: 24px;
    }
  }
}

.tribe_sidebar {
  display: flex;
  flex-direction: column;
  flex: 0 0 293px;
  max-width: 293px;
  min-width: 293px;
  overflow: hidden;
  background-color: var(--card-background);
  border-left: 0.5px solid #6b7694;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  .map_view_btn {
    display: none;
  }

  .tribe_sidebar_bx {
    display: flex;
    flex-direction: column;
    min-height: 0;
  }

  .tribe_sidebar_head {
    padding: 16px 19px;
    border-bottom: 0.5px solid #6b7694;

    .back_profile {
      margin-bottom: 0;
    }

    .back_profile_txt {
      p {
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        color: #e6e9ff;
      }
    }
  }

  .tribe_info_header {
    .chat_header_flx {
      padding: 32px 24px;
      text-align: center;

      .chat_header_img {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .button_close {
        display: none;
      }
    }

    .chat_header_cntnt {
      margin-top: 8px;
      max-width: 225px;
      margin: 0 auto;

      h2 {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        margin: 8px 0;
      }

      ul {
        li {
          font-size: 14px;
          font-weight: 500;
          color: #e6e9ff;
          line-height: 21px;
        }
      }
    }

    .copylink_btn {
      margin: 12px 0 0;

      button {
        font-size: 16px;
        padding: 8px 14px;
      }
    }
  }

  .turn_off_notification {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    margin: 0 24px;
    padding: 24px 0;
    border-bottom: 0.5px solid #6b7694;
    border-top: 0.5px solid #6b7694;

    .switch_toggle_bx {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .turn_off_notification_txt {
      p {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }

  .tribe_info_member {
    padding: 24px 24px;
  }

  .tribe_info_member_head {
    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      margin-bottom: 12px;
    }
  }

  .tribe_info_member_list_bx {
    li {
      padding: 12px 0;
    }

    .tribe_info_member_list {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .tribe_info_member_list_img {
      width: 32px;
      height: 32px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .tribe_info_member_list_flx {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .tribe_info_member_list_txt {
      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 0;
      }
    }
  }

  .member_report {
    .dropdown {
      button {
        background-color: transparent;
        padding: 0;
        border: none;

        &:after {
          content: none;
        }
      }

      .dropdown-menu {
        min-width: 155px;
        background-color: #475169;
        padding: 8px 16px 20px 16px;

        a.dropdown-item {
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          color: var(--body-color);
          padding: 16px 0;
          border-bottom: 0.5px solid #6b7694;
          white-space: normal;

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }

          &:hover {
            background-color: transparent;
            color: grey;
          }
        }
      }
    }
  }

  .tribe_info_header_bx {
    flex: 1;
    overflow-y: auto;
  }

  .tribe_block_member {
    border-top: 0.5px solid #6b7694;
    padding: 16px 0 19px;
    margin: 0 24px;

    .tribe_info_member_list_txt {
      p {
        color: #abb2c3;
        margin-bottom: -7px;
      }

      span {
        display: inline-block;
        font-size: 10px;
        font-weight: 500;
        color: #8a94ad;
        line-height: 15px;
      }
    }

    .block_user {
      position: relative;

      span {
        position: absolute;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background-color: #6b7694;
        display: flex;
        align-items: center;
        justify-content: center;
        right: -2px;
        top: 15px;
      }
    }
  }

  .block_user_skip {
    margin: 12px 0 0;

    button {
      width: 100%;
      font-size: 16px;
    }
  }
}

.sign_in_modal.categories_modal.create_tribe_modal.create_a_poll {
  .name_your_tribe {
    .ask_a_question {
      position: relative;

      .ask_question_flx {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        gap: 10px;
      }

      .form-control {
        padding: 11.5px 16px;
        padding-right: 42px;
      }
    }
  }
}

.allow_multiple {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.switch_toggle {
  display: flex;
}

.switch_toggle input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch_toggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 60px;
  height: 32px;
  background-color: #475169;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switch_toggle label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  max-width: 28px;
  width: 100%;
  height: 28px;
  background: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

.switch_toggle input:checked + label {
  background: var(--blue-backgound);
  transition: 0.3s;
}

.switch_toggle input:checked + label:after {
  right: 2px;
  left: auto;
  transition: 0.3s;
}

.switch_toggle label:active:after {
  width: 28px;
}

.setting_crd {
  background-color: var(--card-background);
  padding: 32px 32px;
  border-radius: 24px;
}

.setting_crd_head {
  margin-bottom: 32px;

  h2 {
    font-size: 35px;
    font-weight: 700;
    line-height: 52px;
    margin-bottom: 0;
  }
}

.notifications_preferences {
  margin-bottom: 40px;
}

.notifications_preferences_head {
  margin-bottom: 24px;

  h3 {
    font-size: 26px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: -0.5px;
    margin-bottom: 24px;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
  }
}

ul.check_lis {
  li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .form-check-input:checked[type="checkbox"] {
    background-color: var(--blue-backgound);
    border-color: transparent;
  }

  .form-check-inline {
    margin-bottom: 0;
  }

  label.form-check-label {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: var(--body-color);
    margin-left: 10px;
  }

  .form-check-input {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 0.5px solid #b8c1d8;
  }
}

.suggest_features {
  textarea.form-control {
    margin-top: 24px;
    background-color: var(--card-background-two);
    border-radius: 8px;
    border: 0.5px solid #6b7694;
    color: var(--body-color);
    padding: 9px 9px;
    caret-color: #8a94ad;

    &:focus {
      box-shadow: unset;
    }
  }

  .custom_rating {
    span svg {
      margin-right: 6px;
    }
  }
}

.setting_footer {
  display: flex;
  gap: 24px;
  justify-content: flex-end;
  padding: 31px 32px;
  background-color: var(--footer-background);
  border: 0.5px solid var(--footer-border);

  button {
    padding: 0.9375rem 2rem;
  }
}

/* --------------------------
       create poll modal
------------------------------ */
.sign_in_modal.categories_modal.create_tribe_modal.create_a_poll {
  .btn-close {
    top: 44px;
    right: 24px;
  }
}

.multiple_tribe_member {
  ul {
    position: relative;
    max-width: 450px;
    margin: 0 auto !important;
    height: 480px;

    li {
      &:nth-of-type(1) {
        position: absolute;
        left: 58px;
        top: 61px;
        width: 91px;
        height: 91px;
      }

      &:nth-of-type(2) {
        width: 125px;
        position: absolute;
        right: 160px;
        height: 125px;
        top: 0;
      }

      &:nth-of-type(3) {
        position: absolute;
        right: 67px;
        width: 77px;
        height: 77px;
        top: 38px;
      }

      &:nth-of-type(4) {
        position: absolute;
        right: 0;
        width: 87px;
        height: 87px;
        top: 115px;
      }

      &:nth-of-type(5) {
        position: absolute;
        width: 109px;
        height: 109px;
        left: 0;
        top: 165px;
      }

      &:nth-of-type(6) {
        position: absolute;
        width: 109px;
        height: 109px;
        left: 120px;
        top: 142px;
      }

      &:nth-of-type(7) {
        position: absolute;
        width: 124px;
        height: 124px;
        top: 149px;
        right: 84px;
      }

      &:nth-of-type(8) {
        position: absolute;
        width: 77px;
        height: 77px;
        right: 0;
        top: 237px;
      }

      &:nth-of-type(9) {
        position: absolute;
        width: 125px;
        height: 125px;
        top: 284px;
        left: 54px;
      }

      &:nth-of-type(10) {
        position: absolute;
        width: 87px;
        height: 87px;
        top: 261px;
        left: 185px;
      }

      &:nth-of-type(11) {
        position: absolute;
        width: 91px;
        height: 91px;
        top: 363px;
        right: 177px;
      }

      &:nth-of-type(12) {
        position: absolute;
        top: 300px;
        right: 57px;

        .plus_member {
          background-color: var(--pink-backgound);
          width: 109px;
          height: 108px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

          h3 {
            font-size: 39px;
            font-weight: 700;
            margin-bottom: 0;
            color: var(--body-color);
          }
        }

        .plus_other_member {
          span {
            font-size: 16px;
            font-weight: 500;
            line-height: 23px;
            display: inline-block;
            color: var(--body-color);
            margin: 18px 0 0;
          }
        }
      }
    }
  }

  li.multiple_tribe_member_tvel {
  }

  li {
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

/* ---------------------
    terms consditions
---------------------- */
.terms_consditions {
  .notifications_preferences {
    padding: 24px 0;
    margin-bottom: 0;
    border-bottom: 0.5px solid #6b7694;

    &:first-child {
      padding-top: 0;
    }
  }

  .notifications_preferences_head {
    margin-bottom: 0;

    h5 {
      font-size: 22px;
      font-weight: 600;
      line-height: 33px;
      margin: 24px 0 16px;

      & + p {
        margin-bottom: 24px !important;
      }
    }

    p {
      line-height: 27px;
    }

    li {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: var(--body-color);
    }
  }

  a {
    color: var(--pink-text-color);
  }
}

.form_location {
  position: relative;

  .form_row_icon {
    position: absolute;
    top: 11px;
    left: 16px;
  }

  input {
    background: transparent;
    width: 100%;
    border: 0.5px solid var(--complete-profile-border);
    border-radius: 8px;
    padding: 12px 16px 12px 42px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--body-color);

    &::placeholder {
      color: #e6e9ff;
    }
  }
}

.infinite-scroll-component__outerdiv {
  height: 100%;

  .infinite-scroll-component {
    height: 100% !important;
    overflow: unset !important;
  }
}

.map_marker {
}

.poll_question {
  p {
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
  }
}

.poll_options {
  .form-group {
    margin-bottom: 0px;

    .form-check-input {
      cursor: pointer;
      background-color: transparent;
      border: 0.5px solid #b8c1d8;

      &:checked[type="radio"] {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='6.5' cy='6.5' r='5' fill='%23FFF614'/%3e%3c/svg%3e ");
      }
    }

    label.form-check-label {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      margin-left: 8px;
    }
  }

  .poll_options_list {
    margin-bottom: 24px;

    .poll_options_list_flx {
      margin-bottom: 8px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 0;
    }
  }

  .progress {
    border-radius: 30px;
    height: 6px;
    background-color: #597cf1;

    .progress-bar {
      background-color: var(--pink-backgound);
      border-radius: 30px;
    }
  }
}

.see_votes {
  padding-top: 8px;

  button.btn.btn-outline-light {
    font-size: 16px;
    padding: 7px 15px;
    width: 100%;
    display: inline-flex;
    gap: 4px;
    justify-content: center;

    &:hover {
      svg {
        path {
          fill: var(--bs-btn-hover-color);
        }
      }
    }
  }
}

.poll_flex {
  .sender_chat {
    max-width: 355px;
    width: 100%;
  }
}

aside.epr-main.EmojiPickerReact {
  max-width: 1440px;
  width: 100% !important;
  height: 330px !important;
  border: none;
}

.EmojiPickerReact {
  --epr-emoji-size: 24px !important;
  --epr-emoji-gap: 50px !important;
  --epr-picker-border-radius: 0 !important;
  background-color: #24242d;

  .epr-header > div {
    padding: 24px 16px;
  }

  .epr-emoji-category-content {
    grid-gap: 3px;
  }

  .epr-search-container {
    input {
      font-family: "Poppins", sans-serif;
      background: transparent;
      border: 0.5px solid #6b7694;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      border-radius: 8px;
      padding: 16px 16px 16px 46px;
      height: 44px;

      &::placeholder {
        color: #abb2c3;
      }
    }
  }

  .epr-icn-search {
    background-position: unset !important;
    left: 16px !important;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.58464 18.0003C13.9569 18.0003 17.5013 14.4559 17.5013 10.0837C17.5013 5.7114 13.9569 2.16699 9.58464 2.16699C5.21238 2.16699 1.66797 5.7114 1.66797 10.0837C1.66797 14.4559 5.21238 18.0003 9.58464 18.0003Z' stroke='%23E6E9FF' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round'/%3E%3Cpath d='M18.3346 18.8337L16.668 17.167' stroke='%23E6E9FF' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E%0A") !important;
  }

  .epr-header {
    background-color: #24242d;
  }

  .epr-emoji-category-content {
    background-color: #24242d !important;
  }

  h2.epr-emoji-category-label {
    font-family: "Poppins", sans-serif;
    background-color: #24242d;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #abb2c3;
  }
}

.serach_gif {
  padding: 24px 16px 0;

  input.form-control {
    background: transparent;
    border: 0.5px solid #6b7694;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border-radius: 8px;
    padding: 16px 16px 16px 46px;
    height: 44px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.58464 18.0003C13.9569 18.0003 17.5013 14.4559 17.5013 10.0837C17.5013 5.7114 13.9569 2.16699 9.58464 2.16699C5.21238 2.16699 1.66797 5.7114 1.66797 10.0837C1.66797 14.4559 5.21238 18.0003 9.58464 18.0003Z' stroke='%23E6E9FF' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round'/%3E%3Cpath d='M18.3346 18.8337L16.668 17.167' stroke='%23E6E9FF' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E%0A");
    background-position: 16px 45%;

    &::placeholder {
      color: #8a94ad;
    }
  }
}

.inner_wraper.profile_mobile {
  max-width: 1160px;
  margin: 0 auto;
}

.inner_wraper.setting_inner_wraper {
  /* max-width: 1160px;
  margin: 0 auto;*/
}

.custm_tab_cntnt.custm_tab_cntnt_mobile {
  .upcomming_event_gird {
    max-width: 1920px;
    margin: 0 auto;
  }
}

.no_result {
  padding: 80px 32px;

  p {
    font-size: 26px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-bottom: 0;
  }
}

.show_result {
  .back_profile {
    margin-bottom: 24px;
  }
}

.search_result {
  padding: 0 32px 32px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;
}

// For search Bar on mobile

.form_warper.searchNewControllerMobile {
  display: none;
  padding: 16px 24px 5px;
}

/* responsive */

@media only screen and (max-width: 1200px) {
  .main_wraper {
    margin-left: 0;
  }

  .coachella {
    h3 {
      font-size: 1.8125rem;
      margin: 0 0 4px 1.875rem;
    }

    ul {
      li {
        p {
          font-size: 16px;
          letter-spacing: 1px;
        }
      }
    }
  }

  .tribe_attending {
    padding: 0 16px 16px 16px;
  }

  .tribe_attending_img {
    width: 30px;
    height: 30px;
  }

  .empire_polo {
    .empire_polo_txt {
      font-size: 16px;
      margin-bottom: 2px;
    }
  }

  .tribe_attending_txt {
    h3 {
      font-size: 1rem;
    }

    p {
      font-size: 1rem;
    }
  }

  .top_pill_flx {
    padding: 1rem 1rem;
  }

  .multi_genre_flx {
    padding: 16px 16px;
  }

  .hero_banner_slide {
    padding-bottom: 0;
  }

  .buy_area {
    padding-left: 1rem;
  }

  .hero_banner_slider {
    margin: 0 1rem;
  }

  .top_pill_flx {
    flex-wrap: wrap;
  }

  .multi_genre_btn_flx {
    button {
      font-size: 14px;
    }
  }

  .welcome_modal {
    .modal-content {
      margin: 0 16px;
    }
  }

  .welcome_modal {
    .welcome_logo {
      padding: 34px 30px;
    }
  }

  .swiper.schedule_slider {
    .calender_slide_img {
      .scaling_icon {
        display: inline-flex;
      }
    }
  }

  .inner_wraper.chat_inner_wrapper {
    padding: 0 16px 16px;
  }
}

@media only screen and (max-width: 1024px) {
  .general_admission_flx {
    grid-template-columns: repeat(1, 1fr);
  }

  .general_admission_crd_head {
    h2 {
      font-size: 1.225rem;
      margin-bottom: 12px;
    }
  }

  .weekend_list_txt {
    flex-direction: column;
    gap: 4px;

    h4 {
      font-size: 1rem;
      padding-left: 0;
    }
  }

  .coachella_music_date_flx {
    flex-direction: column;
    gap: 4px;
  }

  .custm_tab_cntnt {
    padding: 16px 16px;
  }

  .coachella_music {
    margin-bottom: 20px;

    .more_than {
      gap: 12px;
    }
  }

  .coachella_music_festival_txt {
    h3 {
      font-size: 1.225rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  html {
  }

  .btn_blue {
    padding: 11px 10px;
  }

  button.btn.btn-secondary {
    font-size: 16px;
  }

  .coachella {
    margin-left: 0;
    left: 16px;
    top: 126px;
    margin-top: 0;
    padding: 0;

    h3 {
      font-size: 47px !important;
      font-size: 24px;
      text-shadow: 0 0 4px rgb(0 0 0 / 25%);
      margin: 0 0 14px;
    }

    ul {
      position: relative;
      right: 0;
      text-align: right;

      li {
        &:nth-of-type(2) {
          margin-left: 0;
        }

        &:nth-of-type(3) {
          margin-left: 0;
        }

        p {
          font-size: 14px;
          font-weight: 600;
          text-shadow: 0 0 4px rgb(0 0 0 / 25%);
          letter-spacing: 0;
        }
      }
    }
  }

  .tribe_attending_img {
    width: 32px;
    height: 32px;
  }

  .tribe_attending {
    flex-direction: column-reverse;

    gap: 32px;
    bottom: 16px;
  }

  .empire_polo {
    display: flex;
    flex-direction: column;
    text-align: right;

    .empire_polo_txt {
      font-size: 14px;
      font-weight: 500;
      display: inline-block;
      padding-right: 0;
      margin-bottom: 0;
    }

    .empire_polo_txt.empire_polo_indio {
      text-align: right;
    }
  }

  .buy_area_head {
    margin-bottom: 16px;

    h2 {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .buy_area_content_txt {
    p {
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 0;
      line-height: 26px;
    }
  }

  .sign_in_modal {
    .modal-header {
      padding: 30px 16px 0 16px;
    }

    .modal-title {
      margin-bottom: 12px;

      h2 {
        font-size: 2.125rem;
        line-height: unset;
      }
    }

    .modal-body {
      padding: 0 16px 0 16px;

      h4 {
        font-size: 1.275rem;
        margin-bottom: 16px;
      }
    }

    .modal-footer {
      padding: 34px 16px 30px 16px;
    }

    .modal-dialog {
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  .otp_code {
    input {
      height: 52px;
      width: 52px !important;
    }
  }

  .welcome_modal {
    .welcome_logo_img {
      margin-bottom: 12px;

      img {
        width: 150px;
      }
    }

    .welcome_logo_txt {
      margin-bottom: 20px;

      p {
        font-size: 1.175rem;
      }
    }

    .flex_login_container {
      grid-template-columns: repeat(1, 1fr);
    }

    .login_video {
      ::before {
        border-radius: 0;
      }

      video {
        border-bottom-left-radius: 0;
        border-top-right-radius: 24px;
      }
    }

    .modal-content {
      margin: 0 0;
    }
  }

  .welcome_modal .login_video {
    display: none !important;
    opacity: 0;
    visibility: hidden;
    height: 0 !important;

    video {
      border-radius: 0;
      display: none !important;
    }
  }

  /*  
    .welcome_modal.completeyour_profile {
        .welcome_logo {
            padding: 34px 30px;
        }
    }*/

  .top_pill_flx {
    padding: 12px 24px 12px 24px;
  }

  .top_pill_link {
    ul {
      gap: 8px;
    }

    a {
      font-size: 12px;
      padding: 3px 8px;
    }
  }

  .hero_banner_slider {
    margin: 0 24px;
  }

  .multi_genre_flx {
    padding: 16px 16px 0;

    button {
      font-size: 12px;
      font-weight: 700;
      padding: 1.5px 5px;
    }
  }

  .multi_genre_txt {
    h2 {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .hero_banner_slider_video {
    height: 475px;
    margin-bottom: 24px;

    ::before {
      border-radius: 0;
    }
  }

  .event_banner {
    .hero_banner_slider_video {
      margin-bottom: 0;
    }
  }

  .tribe_attending_txt {
    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .swiper.hero_slider {
    padding-bottom: 0;
  }

  .swiper-pagination {
    display: none;
  }

  .hero_banner_slide {
    padding-bottom: 0;
  }

  .buy_area {
    padding: 0 0 0 24px;
    margin-top: 0;
  }

  .gener {
    p {
      font-size: 12px;
      font-weight: 700;
      padding: 2px 6px;
    }
  }

  .gener_txt {
    bottom: 8px;

    p {
      font-size: 20px;
    }
  }

  .buy_area_content {
    background-color: var(--card-background);
    height: 100px;
    max-height: 100%;
    overflow: hidden;
    padding: 8px 16px;

    ul {
      li {
        margin-bottom: 2px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .welcome_modal {
    padding-left: 0 !important;

    .modal-dialog {
      width: 100%;
      margin: 0 !important;
    }

    .login_video {
      min-height: 100vh;

      video {
        border-radius: 0;
      }
    }
  }

  .signin_inner_modal {
    .modal-content {
      min-height: 100dvh;
      border-radius: 0;
      background: linear-gradient(351.21deg, #0c0c0d 33.24%, #262636 95.65%);
    }

    .modal-header {
      padding: 24px 24px 0;
    }

    .modal-dialog {
      margin: 0;
    }

    .modal-title {
      margin-bottom: 24px;

      h2 {
        font-size: 20px;
        line-height: 26px;
        justify-content: center;
      }
    }

    .modal-body {
      text-align: left;
      padding: 16px 24px;

      h4 {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.5px;
        line-height: 26px;
        text-align: center;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
      }
    }

    .phone_number_form {
      padding-top: 32px !important;
    }

    .modal-footer {
      padding: 24px 24px;
      background-color: #292833;
    }

    .phone_label {
      flex: 1 0 0;

      label {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .otp_inner_modal {
    .modal-body {
      p {
        flex-direction: row;
        gap: 16px;
      }
    }

    .otp_code {
      input {
        height: 48px;
        width: 48px !important;
        font-size: 24px;
      }
    }
  }

  h2.back_arrow_signin {
    justify-content: start;

    .back_profile_icon {
      display: block;
    }
  }

  .is_login {
    display: none;

    align-items: center;
    padding: 16px 24px;

    .wlcm_txt {
      h1 {
        margin-bottom: 0;
      }
    }

    .toggle_btn {
      cursor: pointer;
      display: none;
    }

    .form_warper {
      gap: 0;
    }

    form {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 16px;

      input {
        // display: none;
      }

      button.filter_btn {
        position: unset;
      }
    }

    .btn_blue {
      display: none;
    }
  }

  .notification_bar {
    gap: 16px;
  }

  .header_serch {
    display: none;
  }

  .mobile_header_top_m {
    display: flex;
  }

  .welcome_modal.completeyour_profile {
    .welcome_logo {
      padding: 24px 24px;
    }

    .login_video {
      display: none !important;
      visibility: hidden;
      opacity: 0;
    }

    .modal-content {
      min-height: 100dvh;
      background: linear-gradient(351.21deg, #0c0c0d 33.24%, #262636 95.65%);
      border-radius: 0;
      padding-bottom: 260px;
    }

    .completeyour_profile_btn {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #1b1b24;
      border-top: 0.5px solid var(--footer-border);
      padding: 24px 24px;
      z-index: 9;
    }

    h2.back_arrow_signin {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.5px;
      margin-bottom: 40px;
    }

    .personal_info {
      span {
        width: 36px;
        height: 36px;
        font-size: 20px;
      }

      h2 {
        font-size: 20px;
        line-height: 26px;
      }
    }

    .completeyour_profile_btn {
      button {
        height: 56px;
        font-size: 16px !important;
      }
    }

    .integration_social_media_icon {
      .social_media_icon {
        margin-right: -14px;
      }
    }
  }

  .completeyour_profile_photo {
    .completeyour_profile {
      display: block;
    }
  }

  .profile_photo_upload {
    label {
      height: calc(100dvh - 280px);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .social_media_group {
    overflow-y: auto;
    padding-bottom: 120px;
  }

  .connect_txt {
    span {
      font-size: 0;
      display: inline-flex;
    }
  }

  .connect_icon {
    svg {
      display: block;
    }
  }

  .social_media_icon {
  }

  .sign_in_modal.categories_modal.fillter_categories_modal {
    padding-left: 0;

    .modal-dialog {
      margin: 0;
    }

    .modal-content {
      min-height: 100dvh;
      border-radius: 0;
    }

    .modal-title.h4 {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.5px;
    }

    .modal-header {
      padding: 24px 24px 24px 24px;
    }

    .modal-body {
      padding: 24px 24px;
      height: 62vh;
    }

    button.accordion-button {
      font-size: 16px;
      line-height: 24px;
      padding: 24px 0;
    }

    .select_bage_flx {
      .badge.bg-primary {
        font-size: 14px;
        font-weight: 400;
        padding: 5px 16px;
      }
    }
  }

  .main_tittle {
    display: block;
  }

  .sign_in_modal {
    button.btn-close {
      display: block;
      border: 1.5px solid #abb2c3;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding: 0;
      font-size: 8px;
      font-weight: 500 !important;
      color: #fff !important;
      opacity: 1 !important;
      transform: translate(-8px, -11px);
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.292786 0.292997C0.480314 0.105526 0.734622 0.000210801 0.999786 0.000210801C1.26495 0.000210801 1.51926 0.105526 1.70679 0.292997L7.99979 6.586L14.2928 0.292997C14.385 0.197487 14.4954 0.121305 14.6174 0.0688959C14.7394 0.0164869 14.8706 -0.0110994 15.0034 -0.0122532C15.1362 -0.013407 15.2678 0.0118947 15.3907 0.0621756C15.5136 0.112456 15.6253 0.18671 15.7192 0.280602C15.8131 0.374495 15.8873 0.486147 15.9376 0.609043C15.9879 0.73194 16.0132 0.863619 16.012 0.996398C16.0109 1.12918 15.9833 1.2604 15.9309 1.3824C15.8785 1.50441 15.8023 1.61475 15.7068 1.707L9.41379 8L15.7068 14.293C15.8889 14.4816 15.9897 14.7342 15.9875 14.9964C15.9852 15.2586 15.88 15.5094 15.6946 15.6948C15.5092 15.8802 15.2584 15.9854 14.9962 15.9877C14.734 15.9899 14.4814 15.8892 14.2928 15.707L7.99979 9.414L1.70679 15.707C1.51818 15.8892 1.26558 15.9899 1.00339 15.9877C0.741189 15.9854 0.490376 15.8802 0.304968 15.6948C0.11956 15.5094 0.0143908 15.2586 0.0121124 14.9964C0.00983399 14.7342 0.110628 14.4816 0.292786 14.293L6.58579 8L0.292786 1.707C0.105315 1.51947 0 1.26516 0 0.999997C0 0.734833 0.105315 0.480525 0.292786 0.292997Z' fill='%23ABB2C3'/%3E%3C/svg%3E%0A");
    }
  }

  .welcome_modal {
    & .login_video {
      &:before {
        border-radius: 0;
      }
    }
  }

  .event_banner {
    margin: 0 0;

    .hero_banner_slide {
      padding: 0;
    }

    .hero_banner_slider_video {
      video {
        border-radius: 0;
      }
    }

    .tribe_attending {
      display: none;
    }

    .share_btn {
      width: 48px;
      height: 48px;

      .share_btn_icon {
        img {
          width: 24px;
        }
      }
    }

    .coachella {
      top: unset;
      bottom: 48px;

      h2 {
        margin-left: 33px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 40px !important;
      }
    }
  }

  .custom_tab {
    margin: 0 0;

    .nav.nav-tabs {
      padding: 0 24px !important;
    }

    .nav-tabs .nav-link {
      padding: 15px 18px;
    }

    .custm_tab_cntnt {
      padding: 0 0;
    }

    .coachella_music_crd {
      padding: 0 0;
      background: transparent;
      margin-bottom: 0;
    }

    .coachella_music {
      padding: 24px 24px;
      gap: 16px;
      border-bottom: 0.5px solid var(--inner-common-border);
      margin-bottom: 0;

      .more_than {
        justify-content: space-between;
      }
    }
  }

  .trending_tag {
    p {
      font-size: 12px;
    }
  }

  .coachella_music_date_flx {
    padding: 24px 24px;
    border-bottom: 0.5px solid var(--inner-common-border);
  }

  .coachella_music_date {
    align-items: flex-start;
    gap: 16px;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .weekend_list_txt {
    background-color: transparent;
    padding: 0 0 0;

    h4 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .weekend_list {
    li {
      margin-bottom: 16px;
    }
  }

  .general_admission_crd_head {
    h2 {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 8px;
    }
  }

  .weekend_list_btm {
    padding-bottom: 24px;
    border-bottom: 0.5px solid var(--inner-common-border);

    p {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .general_admission_flx {
    gap: 0;
  }

  .general_admission_crd {
    background-color: transparent;
  }

  .vip_card {
    padding-top: 0;

    .weekend_list_btm {
      padding-bottom: 16px;
      border-bottom: none;
    }

    .more_info_accordian {
      margin-top: 0;

      .accordion-item {
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
      }

      button.accordion-button {
        padding: 16px 0;
        font-size: 16px;
        font-weight: 600;
        color: var(--body-color);
      }
    }
  }

  /*-----------------
            profile
    -------------------*/
  .verify_badge {
    display: none;
  }

  .verify_badge_with_name {
    display: block;
  }

  .edit_icon_icon_dsk {
    display: block;
  }

  .profile_wraper {
    gap: 24px;
    flex-direction: column;
    margin-bottom: 0;
    align-items: unset;

    & > div {
      flex: unset;
    }
  }

  .profile_wraper_img {
    position: relative;
    height: 314px;
    width: 360px;
    margin: 0 auto;
    border-radius: 0;

    img {
      border-radius: 0;
    }
  }

  .profile_mobile {
    padding: 0;

    .back_profile {
      padding: 11px 24px 0;
    }

    .back_profile_txt {
      p {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.5px;
      }
    }

    .edit_icon_icon_dsk {
      position: absolute;
      top: 16px;
      right: 16px;
      background-color: #fff;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .user_profile_social {
    padding: 24px 24px 24px;
    border-bottom: 0.5px solid var(--inner-common-border);
  }

  .user_profile_social_head {
    margin-bottom: 16px;

    h3 {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .user_profile_social_media {
    ul {
      display: flex;
      gap: 16px;
    }
  }

  .user_profile_cntnt_txt {
    padding: 0 24px 24px;
    margin-bottom: 0;

    h2 {
      font-size: 30px;
      gap: 8px;

      a {
        display: none;
      }
    }

    p {
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      span.text-truncate {
        width: 200px;
      }
    }
  }

  .user_profile_cntnt {
    padding-bottom: 0;
  }

  .verify_badge {
    span.badge.bg-success {
      font-size: 12px;
      padding: 4.5px 8px;
    }
  }

  .profile_information_crd {
    background-color: transparent;
    padding-bottom: 0;
  }

  .profile_information_crd_head {
    margin-bottom: 0;

    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
    }
  }

  .profile_information_list_txt_email {
    h4 {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 0;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .profile_information_list_txt {
    h4 {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 0;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  ul.profile_information_list {
    li {
      padding: 20px 0;
    }
  }

  .profile_information_flex {
    flex-direction: column;
    gap: 0;
  }

  .music_genre {
    & .music_genre_head {
      h3 {
        font-size: 16px !important;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .select_bage_flx {
      .badge.bg-primary {
        font-size: 14px;
        font-weight: 400;
        padding: 5px 18px;
      }
    }
  }

  .profile_information {
    margin-bottom: 0;
  }

  .integrations_info {
    .integrations_crd_txt {
      p {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .preferences_infomation {
    .profile_information_crd {
      padding: 24px 24px;
    }
  }

  /*-----------------
            event
    -------------------*/
  .festival_playlist_crd {
    .festival_playlist {
      flex-direction: column;
      align-items: unset;
      gap: 18px;
      padding: 24px 24px;
      border-bottom: 0.5px solid var(--inner-common-border);
    }
  }

  .festival_playlist_btn {
    gap: 8px;

    a {
      text-align: center;
      width: 100%;
      padding: 4px 48px;

      img {
        width: 60px;
      }
    }
  }

  .festival_playlist_txt {
    h2 {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }

  .about_tab_cus {
    .festival_playlist_crd.about_festival_playlist {
      padding: 0;
      border-bottom: none;
    }

    .festival_playlist_crd {
      padding: 24px 24px;
      border-bottom: 0.5px solid var(--inner-common-border);
      border-radius: 0;
    }
  }

  .event_information_desc p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .lineup_desc {
    border-bottom: none !important;

    .festival_playlist_txt {
      h2 {
        margin-bottom: 0;
      }
    }
  }

  .lana_dal_rey_head {
    h3 {
      font-size: 30px;
      margin-bottom: 0;
    }
  }

  .lana_dal_rey_list_flx {
    flex-direction: column;
    gap: 0;
  }

  ul.lana_dal_rey_list {
    li {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .schedule_playlist_crd {
    padding-top: 10px !important;
    border-bottom: none !important;

    .festival_playlist_txt {
      margin-bottom: 16px;
    }
  }

  .swiper.schedule_slider {
    .swiper-pagination {
      display: block;
    }
  }

  .event_main_tittle {
    padding: 0 24px;

    .back_profile {
      padding-top: 19px;
      margin-bottom: 16px;
    }
  }

  .more_info_accordian.fillter_accordian.lineup_accordian {
    .accordion-item {
      &:last-child {
        border-bottom: 0.5px solid var(--accordian-border);
      }
    }
  }

  .sign_in_modal.categories_modal {
    .modal-dialog {
      margin: 0;
    }

    .modal-content {
      min-height: 100dvh;
      border-radius: 0;
      background: linear-gradient(351.21deg, #0c0c0d 33.24%, #262636 95.65%);
    }

    .modal-header {
      padding: 24px 24px 24px 24px;
    }

    .modal-title.h4 {
      font-size: 20px !important;
      font-weight: 600 !important;
      line-height: 26px;
      letter-spacing: -0.5px;
    }

    .btn-close {
      position: absolute;
      right: 24px;
      top: 42px;
    }

    .modal-body {
      padding: 24px 24px;
    }

    .music_genre.categoties_genre {
      .music_genre_head {
        margin-bottom: 24px;
      }
    }

    .select_bage_flx {
      gap: 8px;
    }

    .modal-footer {
      padding: 24px 24px;
    }

    .completeyour_profile_btn.profile_photo_btn {
      button {
        font-size: 11px;
        padding: 15px 15px;
      }
    }
  }

  /*------------------------
        setting-suggestion
    ------------------------*/
  .setting_inner_wraper {
    padding: 0;
  }

  .setting_crd {
    background-color: transparent;
    padding: 24px 24px;
    border-radius: 0;
  }

  .setting_crd_head {
    h2 {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.5px;
      line-height: 26px;
    }
  }

  .notifications_preferences_head {
    margin-bottom: 16px;

    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      margin-bottom: 16px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
    }
  }

  .terms_consditions {
    .notifications_preferences_head {
      h5 {
        font-size: 20px;
        line-height: 26px;
      }

      li {
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
      }
    }
  }

  ul.check_lis {
    label.form-check-label {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-left: 8px;
      transform: translateY(3px);
    }
  }

  .notifications_preferences {
    margin-bottom: 32px;
  }

  .setting_footer {
    padding: 24px 24px;

    button {
      font-size: 16px;
      width: 100%;
      height: 56px;
    }
  }

  .suggest_features {
    textarea.form-control {
      margin-top: 16px;
    }
  }

  /*----------------------------------
        request_successfully_modal
    ------------------------------------*/
  .sign_in_modal.categories_modal.request_successfully_modal {
    .modal-body {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .reportsuccessfully_icon {
      margin-bottom: 16px;
    }

    .reportsuccessfully_txt {
      // h2 {
      //   font-size: 30px;
      //   font-weight: 700;
      //   line-height: 45px;
      //   margin-bottom: 16px;
      // }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  /* --------------------------
       report user modal
------------------------------ */
  .sign_in_modal.categories_modal.request_successfully_modal.report_user_modal {
    .modal-body {
      display: unset;
    }

    .reportsuccessfully_txt {
      h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        text-align: left;
      }
    }

    .modal-header {
      padding: 24px 0 0;
    }

    button.btn-close {
      z-index: 1;
    }
  }

  /* --------------------------
       create poll modal
------------------------------ */
  .sign_in_modal.categories_modal.create_tribe_modal.create_a_poll {
    .modal-header {
      .modal-title.h4 {
        font-size: 20px;
        line-height: 26px;
      }
    }

    .name_your_tribe {
      h3 {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 16px;
      }

      .form-control {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 16px;
      }
    }

    .allow_multiple {
      margin-top: 12px;
    }

    .allow_multiple_txt {
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .tool_tip {
    .tooltip-inner {
      left: -34px;
    }
  }

  .more_info_accordian.fillter_accordian {
    .choose_date {
      flex-direction: column;
      gap: 24px;
      margin: 16px 0 0;

      .form_row {
        input {
          margin-bottom: 0;
        }
      }

      .react-datepicker__week {
        display: flex;
        justify-content: space-between;
      }

      .react-datepicker__day-names {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .sign_in_modal.categories_modal.tribein_modal {
    .modal-body {
      h4 {
        font-size: 30px;
        font-weight: 700;
        line-height: 45px;
        margin: 0 0 16px;
        text-align: center;
      }
    }

    .tribesmember_slide_txt {
      text-align: center;

      p {
        display: unset;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .edit_your_age {
      text-align: center;
    }

    .modal-footer {
      padding: 24px 24px 14px;
    }
  }

  .multiple_tribe_member {
    ul {
      max-width: 312px;
      margin: 0 auto !important;
      height: 314px;

      li {
        &:nth-of-type(1) {
          left: 40px;
          top: 42px;
          width: 63px;
          height: 63px;
        }

        &:nth-of-type(2) {
          width: 86px;
          right: 110px;
          height: 86px;
          top: 0;
        }

        &:nth-of-type(3) {
          right: 46px;
          width: 53px;
          height: 53px;
          top: 26px;
        }

        &:nth-of-type(4) {
          width: 60px;
          height: 61px;
          top: 80px;
        }

        &:nth-of-type(5) {
          width: 75px;
          height: 75px;
          left: 0;
          top: 114px;
        }

        &:nth-of-type(6) {
          width: 75px;
          height: 75px;
          left: 83px;
          top: 98px;
        }

        &:nth-of-type(7) {
          width: 81px;
          height: 81px;
          top: 103px;
          right: 62px;
        }

        &:nth-of-type(8) {
          width: 53px;
          height: 53px;
          top: 160px;
        }

        &:nth-of-type(9) {
          width: 86px;
          height: 86px;
          top: 196px;
          left: 37px;
        }

        &:nth-of-type(10) {
          width: 60px;
          height: 60px;
          top: 179px;
          left: 128px;
        }

        &:nth-of-type(11) {
          width: 63px;
          height: 63px;
          top: 251px;
          right: 122px;
        }

        &:nth-of-type(12) {
          top: 207px;
          right: 33px;

          .plus_member {
            width: 75px;
            height: 75px;

            h3 {
              font-size: 26px;
              font-weight: 600;
            }
          }

          .plus_other_member {
            span {
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
              margin: 10px 0 0;
            }
          }
        }
      }
    }
  }

  .sign_in_modal.categories_modal.create_tribe_modal {
    .modal-header {
      padding: 24px 24px 32px 24px;
      top: 44px;
      right: 24px;

      .modal-title.h4 {
        line-height: 26px;
      }
    }

    .modal-footer {
    }

    .name_your_tribe {
      h3 {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 16px;
      }

      .form-control {
        font-size: 14px;
        font-weight: 500;
      }

      .sreach_by_name {
        .form-control {
          background-position: 4%;
          padding-left: 42px;
        }
      }
    }

    .or_look {
      a {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .sugges_tion_flx {
      gap: 8px;
    }

    .sugges_tion_name {
      h3 {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
  }

  .upcoming_tab_flx {
    .header_serch {
      display: none;
    }
  }

  .tribes_you_part {
    .header_serch {
      display: none;
    }
  }

  .inner_wraper.my_event_inner_wrapper {
    padding: 19px 24px 0;

    .back_profile {
      margin-bottom: 0;
    }
  }

  .event_mobile_tab {
    .nav.nav-tabs {
      padding: 0 24px !important;
    }
  }

  .custm_tab_cntnt.custm_tab_cntnt_mobile {
    padding: 24px 24px;

    .upcoming_tab_flx {
      .header_serch {
        display: block;
      }
    }

    .form_warper {
      form {
        display: flex;
        gap: 16px;
        justify-content: flex-end;

        .filter_btn {
          position: unset;
        }

        input {
          display: none;
        }
      }
    }

    .upcomming_event_gird {
      grid-template-columns: repeat(1, 1fr);
      gap: 24px;
    }
  }

  .upcoming_tab_head {
    h2 {
      font-size: 20px;
      line-height: 26px;
    }
  }

  /* chat module */

  .tribe_you_cntnt {
    justify-content: space-between;
  }

  .tribes_you_part_head {
    h3 {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.5px;
    }
  }

  .tribe_you_cntnt_txt {
    h4 {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  .inner_join_tribe_heading {
    padding: 16px 24px 0;
    border-bottom: none;
    display: none;

    .back_profile_txt {
      p {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.5px;
      }
    }
  }

  .chat_flx {
    .chat_header {
      padding: 8px 20px;
    }

    .chat_header_flx {
      align-items: center;
      gap: 12px;
    }

    .chat_header_cntnt {
      h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.5px;
        margin-bottom: 4px;
      }

      ul {
        li {
          font-size: 10px;
          font-weight: 500;
          line-height: 15px;
          padding: 0 9px;

          &::before {
            width: 3px;
            height: 3px;
            top: 54%;
          }
        }
      }
    }

    .chat_header_img {
      max-width: 48px;
      height: 48px;
    }

    .chat_menu_flx {
      span.chat_location {
        display: none;
      }
    }
  }

  .user_chat {
    padding: 24px 24px;

    .sender_chat {
      padding: 8px 16px 8px;

      h4 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 4px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        max-width: 300px;
        word-break: break-word;
      }
    }
  }

  .media_footer {
    padding: 20px 16px;

    .media_footer_flx {
      gap: 8px;
    }

    .media_send_btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      button {
        width: 36px;
        height: 36px;
      }
    }

    .plus_icon {
      display: flex;
      gap: 8px;
      align-items: center;
      span {
        width: 32px;
        height: 32px;
        svg {
          width: 18px;
        }
      }
    }

    .media_input {
      .form-control {
        font-size: 16px;
        font-weight: 400;
        padding: 6.5px 123px 6.5px 16px;
      }
    }
  }

  .user_chat_first {
    & .first_message {
      h4 {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .ice_breaker_idea_head {
      h3 {
        font-weight: 400;
      }
    }

    .ice_breaker_idea_flex {
      span {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
      }
    }
  }

  .notification_bar {
    .notification_crd {
      flex: 1;
      border-radius: 0px;
      display: flex;
      flex-direction: column;
    }

    .dropdown {
      button {
        font-size: 16px;
        display: block;
        width: 100%;
        padding: 16px 16px;
      }
    }

    .dropdown-menu {
      min-width: unset;
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      transform: unset !important;
      height: 100dvh;
      display: none;
      flex-direction: column;
      overflow-y: scroll;
    }

    .show.dropdown {
      .dropdown-menu {
        display: flex;
      }
    }

    .notification_head {
      .notification_head_txt {
        h3 {
          font-size: 20px;
        }
      }
    }

    .today_crd_head {
      h4 {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .show_time_head {
      h5 {
        font-size: 16px;
        line-height: 24px;

        span {
          transform: translateY(-2px);
          display: inline-block;
        }
      }
    }

    .show_time_desc {
      p {
        font-weight: 400;
        line-height: 22px;
      }
    }

    .time_txt {
      p {
        font-size: 600;
      }
    }

    .today_crd_bx {
      max-height: unset;
      overflow-y: unset;
    }
  }

  .notification_footer {
    padding: 32px 24px;
  }

  .notification_footer_btn {
    width: 100%;
  }

  .notification_footer_txt {
    p {
      display: none;
    }
  }

  .sign_in_modal.welcome_modal.completeyour_profile.completeyour_profile_photo {
    .music_bage {
      padding-bottom: 110px;
    }
  }

  .inner_wraper.chat_inner_wrapper {
    border-bottom: none;
    padding: 19px 16px 0;

    .back_profile_txt {
      p {
        font-size: 20px;
        letter-spacing: -0.5px;
      }
    }
  }

  .EmojiPickerReact {
    .epr-search-container {
      input {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .inner_wraper.setting_inner_wraper {
    .back_profile {
      padding: 19px 24px 0;
      margin-bottom: 0;
    }
  }

  .no_result {
    padding: 24px 24px;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .show_result {
    .back_profile {
      margin-bottom: 16px !important;
    }

    .back_profile_txt {
      p {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }

  .search_result {
    padding: 0 24px 24px;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }

  .terms_consditions {
    .setting_crd_head {
      margin-bottom: 0;
    }
  }

  .form_warper input.form-control.active {
    display: block;
    // position: absolute;
    bottom: -14px;
    z-index: 1;
    width: 90%;
    padding: 3px 10px 3px 10px;
    font-size: 14px;
    background: #21212e;
    right: 0;
    left: 0;
    margin: 0 auto;
  }

  .main_header_flex .form_warper {
    position: static;
  }

  .main_header_flex form {
    position: static !important;
  }

  .main_header_flex.d-flex.is_login {
    position: relative;
    flex-direction: column;
    align-items: unset;
    gap: 12px;
  }

  .main_header_flex .form_warper form input:focus ~ .seacrh_corss {
    position: absolute;
    top: 63px;
    z-index: 1;
    right: 34px;
  }

  .poll_options .poll_options_list .poll_options_list_flx {
    margin-bottom: 4px;
  }

  .poll_options .poll_options_list {
    margin-bottom: 8px;
  }

  .poll_options .progress {
    height: 4px;
  }

  .main_header_flex.d-flex.is_login .form_warper input.form-control {
    padding: 9.5px 16px 9.5px 39px;
  }

  // Search Bar on Mobile

  .form_warper.searchNewControllerMobile {
    display: block;
  }

  // User Details

  .sign_in_modal.categories_modal.create_tribe_modal {
    .modal-body.newModalBodyController {
      padding: 0 40px 40px;
    }
  }

  // New Chat Header

  // .chat_header.newChatHeadController {
  //   margin-top: 98px;
  // }

  // .media_footer.newUserFooter {
  //   position: fixed;
  //   bottom: 0;
  // }

  // .media_footer.newUserFooter{
  //   position: fixed;
  //   bottom: 0;
  // }
}

@media only screen and (max-width: 576px) {
  .multi_genre_flx {
    gap: 10px;
  }

  .sign_in_modal {
    .phone_label {
    }

    .phone_number_form {
      padding-top: 46px;
      display: flex;
      gap: 16px;
    }
  }

  .otp_inner_modal {
    .otp_code {
      input {
        height: 44px;
        width: 44px !important;
        font-size: 24px;
      }
    }
  }

  .tribe_you_crd {
    gap: 12px;
  }

  .tribe_you_cntnt {
    padding: 16px 8px 10px 0;
  }

  .buy_area_content_txt p {
    font-size: 0.795rem !important;
  }
}

@media only screen and (max-width: 409px) {
  .buy_area_content_txt p {
    font-size: 0.775rem !important;
  }
}

.icon_active svg path {
  fill: #ff53de;
}

.is_btn_active {
  background-color: var(--blue-backgound);
  color: var(--body-color);
}

.cursor-pointer {
  cursor: pointer;
}

.is_grey input.form-control {
  background-color: #35343e !important;
}

.welcome_modal.completeyour_profile {
  .is_grey {
    .react-datepicker__input-container {
      input {
        background-color: #35343e !important;
      }
    }
  }
}

.chat_img {
  max-width: 32px;
  width: 100%;
  height: 32px;
  border-radius: 50%;
}

.chat_img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

@media only screen and (max-width: 767px) {
  .user-details-form {
    display: flex;
    flex-direction: column;
    height: 92vh;
  }

  .btn-pad-second {
    margin-top: auto;
  }
}

.placeHolderCenter::placeholder {
  // padding: 10px 65px 10px 6px;
  line-height: 50px;
}

.react-datepicker__header {
  padding: 14px 0px !important;
}

@media only screen and (max-width: 567px) {
  .sign_in_modal.categories_modal .modal-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.buy-area-wrap {
  padding-top: 2.5rem;
}
