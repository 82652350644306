.main_header_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
}

.header_logo {
  display: none;
}

.wlcm_txt {
  margin-right: 16px;

  h1 {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--heading-color);
    margin-bottom: 0;
  }
}

.form_warper {
  flex: 1 0 0;
  max-width: 36.0625rem;
  display: flex;
  align-items: center;
  gap: 29px;
  justify-content: flex-end;

  input.form-control {
    font-size: 18px;
    font-weight: 500;
    color: var(--input-color);
    background: transparent;
    border: 0.5px solid var(--input-border-color);
    padding: 13.5px 16px 13.5px 48px;
    background-image: url("../img/search-icon.svg");
    background-repeat: no-repeat;
    background-position: 5% 52%;
    border-radius: 8px;

    &::placeholder {
      color: var(--placeholder-color);
    }
  }

  form {
    max-width: 361px;
    width: 100%;
    position: relative;

    .filter_btn {
      position: absolute;
      right: 16px;
      padding: 0;
      border: none;
      background-color: transparent;
      top: 50%;
      transform: translateY(-50%);

      span.filtter_count {
        background-color: var(--pink-backgound);
        color: var(--body-color);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        font-size: 8px;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        right: -3px;
        top: -1px;
      }
    }
  }
}

.btn_blue {
  padding: 0.9375rem 1rem;
  flex: 0 0 133px;
}

.is_login {
  .notification_count {
    display: block;
  }
}

.notification_count {
  position: relative;

  .notification_badge {
    display: block;
    position: absolute;
    top: 3px;
    right: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--pink-backgound);
  }
}

.notification_bar {
  .dropdown {
    button {
      border: none;
      font-size: 14px;
      padding: 6.5px 8px;

      &::after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    background-color: var(--card-background);
    box-shadow: 0px 0px 40px 0px #0000004d;
    min-width: 485px;
    padding: 0 0;
  }

  .notification_crd {
    border-radius: 16px;

    .cross_btn {
      cursor: pointer;
    }
  }

  .notification_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 24px 24px 0;

    .notification_head_txt {
      h3 {
        font-size: 26px;
        font-weight: 600;
        line-height: 26px;
        margin-bottom: 0;
        letter-spacing: -0.5px;
      }
    }
  }

  .show_time_card {
    background-color: #35343e;
    padding: 16px 16px;
    border-radius: 8px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 24px;
    }
  }

  .today_crd_head {
    margin-bottom: 16px;

    h4 {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      margin-bottom: 0;
    }
  }

  .show_time_head_flx {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .time_txt {
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      margin-bottom: 0;
    }
  }

  .show_time_head {
    h5 {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      margin-bottom: 0;

      span {
        margin-right: 4px;
        transform: translateY(-1px);
      }
    }
  }

  .show_time_desc {
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      margin-bottom: 0;
    }
  }

  .show_time {
    .time_txt {
      p {
        color: var(--pink-text-color);
      }
    }
  }

  .hydration_reminder {
    .time_txt {
      p {
        color: var(--blue-text-color);
      }
    }
  }
}

.notification_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.notification_footer {
  background-color: #292833;
  border-top: 0.5px solid #475169;
  padding: 16px 24px;
}

.notification_footer_txt {
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0;
  }
}

.today_crd_bx {
  padding: 0 24px 0px;
  max-height: 678px;
  overflow-y: scroll;
}

.toggle_btn_desk {
  display: none;
}

.admin_img_header_dropdown {
  position: relative;
  cursor: pointer;
  .logout_btn {
    position: absolute;
    right: 0;
    background-color: var(--card-background);
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all;
  }
}

.admin_img_header_dropdown {
  &:hover {
    .logout_btn {
      opacity: 1;
      visibility: visible;
      transition: 0.3s all;
      border: 0.5px solid #475169;
      a {
        padding: 9px 16px;
      }
    }
  }
}
.hidden  {
/*   position: absolute;
  top: 15px;
  right: 15px;
  display: none; */
}
.main_header_flex {
  .form_warper{
    form{
      input{
        &:focus{
          background-image: none;
          padding-left: 16px;
          border: 1px solid #2957ff;
          &~ .seacrh_corss{
            display: block;
          }
          &~ .filter_btn{
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .is_login {
    .notification_bar {
      .admin_img_header {
        display: none;
      }
    }
  }

  .toggle_btn_desk {
    display: block;
  }

  .header_logo {
    display: block;
  }

  .main_header_flex {
    flex-direction: column;
    padding: 15px 15px;
  }

  .wlcm_txt {
    display: flex;
    align-items: center;
    gap: 8px;

    h1 {
      font-size: 20px;
    }
  }

  .form_warper {
    width: 100%;
    max-width: unset;
    gap: 20px;

    form {
      max-width: unset;
    }
  }
}
 
@media only screen and (max-width: 1200px) {
  .form_warper {
    input.form-control {
      background-position: 10px 52%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .form_warper {
    gap: 10px;

    input.form-control {
      padding: 9.5px 16px 9.5px 31px;
    }

    form {
      button.filter_btn {
        right: 8px;
        top: 10px;
        transform: unset;
      }
    }
  }

  .is_login {
    .notification_count {
      display: none;
    }
  }
  .main_header_flex {
    .notification_bar{
     display: none !important;
    }
  }
  .main_header_flex .form_warper form input:focus{
    padding-left: 16px !important;
  }
}
