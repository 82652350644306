.event-card {
  background-color: var(--card-background);
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  border-radius: 0.625rem;
  max-width: 31.25rem;
  min-height: 100%;



  .event-card-genre  {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    color: white;
    z-index: 1;
    padding: 0.25rem 0.5rem;
    font-size: 12px;
    font-weight: 600;
    border-radius: 0.6rem;
  }


  .event-card-countdown { 
    z-index: 1;
    background: #F3A15D;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    padding: 0.5rem 0.5rem;
    border-radius: 0.625rem;
    font-size: 0.75rem;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    span { 
      line-height: 100%;
      text-align: center;
    }

    .event-card-countdown-section { 
      display: flex;
      flex-direction: column;
    }
    .event-card-countdown-label { 
      font-size: 0.75rem;
      margin-top: 0.25rem;
      opacity: 0.9;
    }

    .event-card-countdown-value { 
      font-size: 1rem;
      font-weight: bold;
    }
  }
  &:hover {
    .event-card-title {
      text-decoration: underline;
    }
    img {
      transition: all 0.1s linear;
      transform: scale(1.05);
    }
  }
  .event-card-image-wrap {
    position: relative;
    overflow: hidden;
  }

  .event-card-title {
    color: white;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.625rem; /* 130% */
    letter-spacing: -0.04375rem;
    margin-bottom: 0.5rem;
    text-overflow: ellipsis;
    max-width: 31.25rem;
    overflow: hidden;
  }

  .event-card__image {
    width: 100%;
    height: 100%;
    min-height: 12.5rem;
    object-fit: cover;
    border-radius: 0.625rem 0.625rem 0rem 0rem;
    max-height: 12.5rem;
    transition: scale(1);
    transition: all 0.1s linear;
    position: relative;
    z-index: 0;
  }

  svg {
    width: 1rem;
    margin-right: 0.5rem;
  }

  .event-card-content {
    padding: 1rem;
  }

  .event-card-text {
    color: white;
    font-size: 0.7rem;
    font-weight: 500;
    opacity: 0.8;
    display: flex;
    align-items: center;
    gap:8px;

    @media(min-width: 48rem) {
      font-size: 0.75rem;
    }
  }
}

.event-card-wrap {
  gap: 1.5rem;
  display: grid;
  margin-bottom: 1.5rem;
  @media (min-width: 33rem) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 64rem) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.loader {
  width: 2rem;
  height: 2rem;
  border: 0.313rem solid #fff;
  border-bottom-color: var(--color-primary);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.events-filter-area {
  display: flex;
  flex-direction: column;
  width: fit-content;
  @media (min-width: 42rem) {
    justify-content: center;
    align-items: center;
    input {
      max-width: 25rem;
    }
  }
}

.events-phone-only {
  display: block;
}

@media (min-width: 48rem) {
  .events-phone-only {
    display: none;
  }
}

.events-desktop-only {
}

.no-events {
  text-align: center;
  padding: 4rem 0rem;
  .no-events-title {
    color: white;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -0.0125rem;
  }

  .no-events-paragraph {
    color: #f4f4f4;
  }
}

.events-filters {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.events-wrap {
  max-width: 95%;
  margin: 0 auto;
}

.events-filter-area {
  width: auto;
  border-radius: 3.125rem;
  padding: 0.5rem 1.5rem;
  @media (min-width: 42rem) {
    padding: 0.5rem 2.5rem;
    width: 100%;
    max-width: 50rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    background: var(--bs-gray-900);
    border: 0.063rem solid var(--bs-gray-800);
    .IconButton {
      height: 2rem;
      box-sizing: border-box;
    }

    .events-filters {
      width: 100%;

      @media (min-width: 48rem) {
        margin-top: 0rem;
        width: auto;
      }
    }
  }
}

.filter-label {
  color: #b1b1b1;
  font-size: 0.75rem;
  font-weight: 500;
}

.location-wrap {
  @media (min-width: 48rem) {
    border-right: 0.0625rem solid rgba(217, 217, 217, 0.22);
  }
}

.button-load-more {
  margin-top: 2.5rem;
  text-align: center;
}