.login-page-gradient {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    rgb(2 6 23) 0%,
    rgb(55 48 163) 50%,
    rgb(2 6 23) 100%
  );
  background-size: 100% 400%;
  animation: gradientAnimation 20s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  z-index: -1;
}

@keyframes gradientAnimation {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

.login-page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
}
