@import "bootstrap/dist/css/bootstrap.min.css";
@import "../src/assets/scss/main-style.scss";

body {
  position: relative;
  margin: 0;
  padding: 0;
}
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(351.21deg, #0c0c0d 33.24%, #262636 95.65%);
  z-index: -9;
}
#not-found {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.form-sign {
  width: 500px;
  height: 400px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 12%;
  left: 35%;
}
.form-container,
.login-form,
.forget-password-form,
.reset-password-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

#forget-password-page {
  display: flex;
  height: 100vh;
  align-items: center;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  color: #333;
  margin-left: 0;
}

.form-input {
  width: calc(100% - 10px);
  padding: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error-message {
  color: #e74c3c;
  font-size: 14px;
  margin-top: 5px;
}

.is_btn_active {
  background-color: #2957ff !important;
  color: #ffffff !important;
}

.submit-button {
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #2980b9;
}

.link {
  color: #3498db;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.additional-links {
  margin-top: 10px;
}

.additional-links p {
  margin: 0;
  display: inline;
}

.additional-links p a {
  color: #3498db;
  text-decoration: none;
}

.additional-links p a:hover {
  text-decoration: underline;
}

.forget-password-form .title {
  text-align: center;
  margin-bottom: 20px;
}

.loader-container,
.content-container {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

.btn-big-submit {
  padding: 24px 0px !important;
  background-color: #2957ff !important;
  visibility: visible !important;
  color: white !important;
}

.btn-pad-second {
  padding: 24px 0px !important;
  font-size: 1.375rem !important;
  font-weight: 700 !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-container {
  text-align: center;
  margin-top: 50px;
}

.message-box {
  text-align: center;
}

.message {
  font-size: 24px;
  color: #333;
}

.check-icon {
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 20px auto;
}

.info-text {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}

.login-button {
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #2980b9;
}

/* Profile.css */

.profile-container {
  margin: 20px;
}

.profile-header {
  text-align: center;
  margin-bottom: 20px;
}

.profile-details {
  text-align: center;
}

.profile-name {
  font-size: 24px;
}

.profile-email,
.profile-phone {
  font-size: 18px;
  margin-bottom: 10px;
}

.profile-image {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.profile-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.profile-button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.profile-button:hover {
  background-color: #2980b9;
}

/* EditProfile.css */

.edit-profile-container {
  margin: 20px;
}

.edit-profile-title {
  text-align: center;
  margin-bottom: 20px;
}

.edit-profile-form {
  max-width: 400px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  color: #333;
  font-weight: bold;
}

.form-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error-message {
  color: #e74c3c;
  font-size: 14px;
  margin-top: 5px;
}

.profile-image {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin: 20px auto;
  display: block;
}

.file-input {
  display: none;
}

.choose-file-button {
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button {
  background-color: #27ae60;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

/* CourtList.css */

.court-header {
  margin: 20px;
}

.court-list-title {
  text-align: center;
  margin-bottom: 20px;
}

.court-item {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.court-name {
  font-size: 18px;
  margin-bottom: 10px;
}

.court-info {
  margin-bottom: 5px;
}

.image-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.court-image {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin-right: 10px;
}

.court-action-button {
  margin-top: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button {
  background-color: #e74c3c;
  color: #fff;
}

.edit-button {
  background-color: #3498db;
  color: #fff;
}

.action-button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3498db;
  color: #fff;
}
/* AddCourt.css */

.add-court-header {
  display: flex;
  align-items: center;
}

.back-button {
  margin-right: 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.add-court-title {
  margin-bottom: 20px;
}

.add-court-form {
  max-width: 400px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.error-message {
  color: #e74c3c;
  margin-top: 5px;
}

.submit-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}
.edit-court-container {
  margin: 20px;
}

.edit-court-title {
  margin-bottom: 20px;
}

.edit-court-form {
  max-width: 400px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

.edit-label {
  display: block;
  margin-bottom: 5px;
}

.edit-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.edit-error {
  color: #e74c3c;
  margin-top: 5px;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.court-image {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.choose-files-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.pac-container {
  z-index: 1100 !important;
}
.performerName {
  color: #fff;
}

