.buy-tickets-text {
  color: white;
  font-weight: 500;
  letter-spacing: -0.2px;
  padding: 8px 0px;
  display: flex;
  align-items: center;
  opacity: 0.7;
  img {
    margin-right: 8px;
  }
}
.buy-get-tickets-button {
  background: #fff614;
  color: #1a1528;
  height: 2.5rem;
  border-radius: 50px !important;
  padding: 0.5rem 1rem;
  display: inline-flex;
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.875rem;
  align-items: center;
  justify-content: center;
}



// styling for the Jointribe page. Putting these here temporarily. I Don't want to put it in the ginourmous scss file. 

.buy-ticket-area-desktop { 
  display: none;
  @media(min-width: 768px) { 
    display: block;
  }
}



.buy-ticket-area-phone { 
  display: flex; 
  justify-content: flex-end;
  width: 100%;
  padding: 0 24px;
  @media(min-width: 768px) { 
    display: none;
  }
}