footer.main_footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 1.9375rem 2rem;
  background-color: var(--footer-background);
  border: 0.5px solid var(--footer-border);
  gap: 1rem;
}
footer.main_footer {
  p {
    font-size: 1.375rem;
    font-weight: 700;
    margin-bottom: 0;
  }

  .btn-tickets {
    background: var(--yellow-color);
  }
}
.footer_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 1rem;
  > div {
    width: auto;
  }
  button,
  a {
    font-size: 1.375rem;
    font-weight: 700;
    display: inline-flex;
    padding: 0 1.75rem;
    width: fit-content;
    height: 4.063rem;
    align-items: center;
    justify-content: center;
  }
}

/* responsive */
@media only screen and (max-width: 767px) {
  footer.main_footer {
    justify-content: flex-end;
    padding: 1.5rem;
    flex-direction: column;
    gap: 1rem;
    p {
      font-size: 1.125rem;
      text-align: center;
    }
    .footer_btn {
      width: 100%;
      flex-direction: column;
      > div {
        width: 100%;
      }
    }

    .btn_blue {
      font-size: 1rem;
      padding: 12px 30px;
      width: 100%;
    }
  }
}
