@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    font-family: "Poppins", sans-serif !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--heading-color) !important;
  }
}

html {
  color-scheme: light only;
}

:root {
  color-scheme: light only;
  --heading-color: rgb(255 255 255);
  --border-color: rgb(255 255 255);
  --border-width: 0.0313rem;
  --placeholder-color: #abb2c3;
  --input-color: #fff;
  --body-color: #fff;
  --blue-backgound: #2957ff;
  --blue-border: #2957ff;
  --sidebar-link: #fff;
  --pink-backgound: #ff53de;
  --pink-border: #ff53de;
  --yellow-backgound: #fff614;
  --yellow-border: #fff614;
  --card-background: #24242d;
  --sidebar-border: #6b7694;
  --footer-background: #1b1b24;
  --footer-border: #080618;
  --background-white: #fff;
  --blue-color: #2957ff;
  --card-background-two: #1b1b24;
  --card-background-inner: #292833;
  --accordian-border: #6b7694;
  --pink-text-color: #ff53de;
  --blue-text-color: #2957ff;
  --secondary-btn: #475169;
  --secondary-btn-color: #abb2c3;
  --input-border-color: #e6e9ff;
  --complete-profile-border: #6b7694;
  --inner-common-border: #475169;
  --light-lable-color: #b8c1d8;
  --white-background: #fff;
  --card-border: #abb2c3;
  --card-background-tribe: #35343e;
  --card-border-tribe: #abb2c3;
  --yellow-color: #fff614;
  --plus-border: #8a94ad;
}

@supports (-webkit-touch-callout: none) {
  .min-h-screen {
    min-height: -webkit-fill-available;
  }

  body {
    min-height: -webkit-fill-available;
  }
}

/* For smaller devices */
@media screen and (max-height: 667px) {
  .message-input-area {
    padding-bottom: calc(env(safe-area-inset-bottom) + 0.5rem);
  }
}
