.layout {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
}
.content {
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: 100%;
  width: 100%;
}


.user-name, .user-greeting{
  font-size: 1rem;
  font-weight: 600;
  color: white;
  opacity: 0.9
}


.user-wrap {
  display: inline-flex; 
  align-items:center; 
  gap:8px;
}

.user-greeting { 
  opacity: 0.5;

}

.header.newHeaderMobileController {
}
@media only screen and (max-width: 992px) {
  .layout {
    height: calc(100dvh);
  }
}
@media only screen and (max-width: 767px) {
  .layout {
    height: calc(100dvh);
  }
}


* { 
  box-sizing: border-box !important; 
} 




.__date-picker__ .react-datepicker-wrapper { 
  width: 100% !important; 
}

.__date-picker__ .react-datepicker__input-container {
  width: 100% !important;
  max-width: none !important; 
}
.__date-picker__ input { 
  
  width: 100% !important; 
}