.buy_area_crd_video_bx {
    position: relative;
}

.buy_area_crd {
    border-radius: 8px;
    overflow: hidden;
}

.gener {
    position: absolute;
    top: 8px;
    right: 8px;

    p {
        background: var(--pink-backgound);
        display: inline-block;
        font-weight: 700;
        padding: 2px 6.5px;
        margin-bottom: 0;
        border-radius: 4px;
    }
}

.gener_txt {
    position: absolute;
    bottom: 8px;
    left: 16px;

    p {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.5px;
        margin-bottom: 0;
    }
}

.buy_area_content {
    background-color: var(--card-background);
    padding: 16px 16px;
    height: 100px;
    max-height: 100%;
    overflow: hidden;
}

.buy_area_content_bx {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.buy_area_content_txt {
    p {
        font-size: 0.875rem;
        font-weight: 500;
        margin-bottom: 0;
    }
}

.buy_area_crd_video {
    position: relative;
    height: 180px;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.835171568627451) 0%, rgba(0, 212, 255, 0) 35%);
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .event-banner {
        width: 100%;
    }
}